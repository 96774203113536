import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  div#top {
    display: flex;
    align-items: baseline;
    justify-content: center;
    > strong {
      font-size: 24px;
      margin-bottom: 15px;
      color: #f47920;
      margin-right: 20px;
    }
    svg {
      cursor: pointer;
    }
  }
  nav {
    background-color: rgba(0, 0, 0, 0.1);
    width: 250px;
    height: 100%;
    box-shadow: 1px 2px 3px #ddd;
    border-radius: 4px;
    left: ${props =>
      props.active
        ? '0'
        : '-100%'}; /*é com essa propriedade que o menu fica escondido a esquerda*/
    transition: left 0.5s;
  }

  nav li {
    color: #333;
    font-size: 20px;
    padding: 18px;
  }
`;

export const WaitList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 800px;
  width: 100%;
  padding: 5px 5px;
`;

export const Patient = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  div#content {
    display: flex;
    flex-direction: column;
    div#header {
      border-radius: 2px;
      font-weight: bold;
      strong {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: 10px;
        > div {
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          height: 24px;
          width: 24px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
    }

    div#footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
          font-size: 12px;
        }
        agreement {
          font-size: 12px;
        }
      }
      div#buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
`;

export const Button = styled.button`
  padding: 5px;
  margin-left: 5px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  background-color: ${props => props.color && props.color};
  color: #fff;
`;
