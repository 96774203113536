import React, { useEffect, useState } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';

import api from '~/services/api';
import history from '~/services/history';

import { editProfessionalRequest } from '~/store/modules/professional/actions';

import { Container } from '../styles';
import ReactSelect from '~/components/ReactSelect';
import SignatureInput from '../SignatureInput';
import Input from '~/components/Input';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  full_name: Yup.string().required('O nome completo é obrigatório'),
  email: Yup.string().email('Informe um email válido'),
  color: Yup.string().required('Informe a cor da agenda do profissional'),
  specialty_id: Yup.string().required('A especialidade é obrigatória'),
  signature_id: Yup.number(),
  crefito: Yup.string().required('O crefito é obrigatório'),
});

export default function Professional({ match }) {
  const [load, setLoad] = useState(false);
  const loading = useSelector(state => state.professional.loading);
  const [specialties, setSpecialties] = useState([]);
  const [professional, setProfessional] = useState({});
  const dispatch = useDispatch();

  function handleSubmit(data) {
    data.id = professional.id;
    dispatch(editProfessionalRequest(data));
  }

  useEffect(() => {
    async function loadSpecialties() {
      const response = await api.get('specialties');
      const data = response.data.specialties.map(specialty => ({
        id: specialty.id,
        title: specialty.description,
      }));
      setSpecialties(data);
    }
    async function loadProfessional() {
      const response = await api.get('professionals', {
        params: {
          id: match.params.professional,
        },
      });

      setProfessional(response.data);
    }

    async function loadFunctions() {
      setLoad(true);
      await loadSpecialties();
      await loadProfessional();
      setLoad(false);
    }

    loadFunctions();
  }, []); // eslint-disable-line

  return (
    <Container loading={loading} load={load}>
      {load ? (
        <div id="load">
          <FaSpinner size={24} color="#f47920" background="none" />
        </div>
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialData={professional || ''}
          schema={schema}
        >
          <Input
            name="name"
            placeholder="Informe o nome"
            label="Primeiro Nome"
          />

          <Input
            name="full_name"
            placeholder="Informe o nome completo"
            label="Nome Completo"
          />

          <Input
            name="email"
            type="email"
            placeholder="Informe o email"
            label="Email"
          />

          <Input
            name="crefito"
            placeholder="Informe o crefito"
            label="Crefito"
          />

          <Input
            name="color"
            type="color"
            defaultValue=""
            placeholder="Informe a cor para a agenda"
            label="Cor para a agenda"
          />

          <hr />

          <ReactSelect
            name="specialty_id"
            label="Especialidade"
            placeholder="Infome a especialidade"
            options={specialties}
            isClearable
          />

          <SignatureInput name="signature_id" label="Assinatura Eletrônica" />

          <button type="submit">
            {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
          </button>
          <button
            id="cancel"
            type="button"
            onClick={() => history.push('/professionals')}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Container>
  );
}

Professional.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      professional: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
