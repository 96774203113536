export function createAgreementRequest(data) {
  return {
    type: '@agreement/CREATE_AGREEMENT_REQUEST',
    payload: { data },
  };
}
export function createAgreementSuccess(agreement) {
  return {
    type: '@agreement/CREATE_AGREEMENT_SUCCESS',
    payload: { agreement },
  };
}
export function createAgreementFailure() {
  return {
    type: '@agreement/CREATE_AGREEMENT_FAILURE',
  };
}
export function editAgreementRequest(data) {
  return {
    type: '@agreement/EDIT_AGREEMENT_REQUEST',
    payload: { data },
  };
}
export function editAgreementSuccess(agreement) {
  return {
    type: '@agreement/EDIT_AGREEMENT_SUCCESS',
    payload: { agreement },
  };
}
export function editAgreementFailure() {
  return {
    type: '@agreement/EDIT_AGREEMENT_FAILURE',
  };
}
export function loadAgreementRequest(data) {
  return {
    type: '@agreement/LOAD_AGREEMENT_REQUEST',
    payload: { data },
  };
}
export function loadAgreementSuccess(agreement) {
  return {
    type: '@agreement/LOAD_AGREEMENT_SUCCESS',
    payload: { agreement },
  };
}
export function loadAgreementFailure() {
  return {
    type: '@agreement/load_AGREEMENT_FAILURE',
  };
}
