export function loadWaitRequest() {
  return {
    type: '@wait/LOAD_WAIT_REQUEST',
  };
}
export function loadWaitSuccess(wait) {
  return {
    type: '@wait/LOAD_WAIT_SUCCESS',
    payload: { wait },
  };
}
export function loadWaitFailure() {
  return {
    type: '@wait/LOAD_WAIT_FAILURE',
  };
}
export function createWaitRequest(data) {
  return {
    type: '@wait/CREATE_WAIT_REQUEST',
    payload: { data },
  };
}
export function createWaitSuccess(wait) {
  return {
    type: '@wait/CREATE_WAIT_SUCCESS',
    payload: { wait },
  };
}
export function createWaitFailure() {
  return {
    type: '@wait/CREATE_WAIT_FAILURE',
  };
}
export function deleteWaitRequest(data) {
  return {
    type: '@wait/DELETE_WAIT_REQUEST',
    payload: { data },
  };
}
export function deleteWaitSuccess(wait) {
  return {
    type: '@wait/DELETE_WAIT_SUCCESS',
    payload: { wait },
  };
}
export function deleteWaitFailure() {
  return {
    type: '@wait/DELETE_WAIT_FAILURE',
  };
}
