import React, { useRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

import PropTypes from 'prop-types';

import { useField } from '@rocketseat/unform';

export default function DatePicker({ name, mask, dateFormat, label, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <ReactDatePicker
        customInput={<InputMask mask={mask} />}
        name={fieldName}
        selected={selected}
        onChange={date => setSelected(date)}
        ref={ref}
        dateFormat={dateFormat || 'dd/MM/yyyy'}
        {...rest}
      />
      {error && <span>{error}</span>}
    </>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
};

DatePicker.defaultProps = {
  mask: '',
  label: '',
  dateFormat: '',
};
