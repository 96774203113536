import React from 'react';
import { Container } from './styles';

import Schedule from '~/components/Schedule';

export default function Dashboard() {
  return (
    <Container>
      <Schedule />
    </Container>
  );
}
