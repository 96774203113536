import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  MdAddCircle,
  MdPersonAdd,
  MdWork,
  MdLocalHospital,
} from 'react-icons/md';

import { Container, Badge, MenuItems, MenuItem } from './styles';

export default function MenuRight() {
  const [visible, setVisible] = useState(false);

  function handleToggleVisible() {
    setVisible(!visible);
  }
  return (
    <Container>
      <Badge onClick={handleToggleVisible}>
        <MdAddCircle color="#fff" size={24} />
      </Badge>

      <MenuItems visible={visible}>
        <MenuItem>
          <MdPersonAdd color="#fff" size={24} />
          <Link to="/patient" onClick={handleToggleVisible}>
            <p>Novo Paciente</p>
          </Link>
        </MenuItem>
        <MenuItem>
          <MdWork color="#fff" size={24} />
          <Link to="/professional" onClick={handleToggleVisible}>
            <p>Novo Profissional</p>
          </Link>
        </MenuItem>
        <MenuItem>
          <MdLocalHospital color="#fff" size={24} />
          <Link to="/agreements" onClick={handleToggleVisible}>
            <p>Convênios</p>
          </Link>
        </MenuItem>
      </MenuItems>
    </Container>
  );
}
