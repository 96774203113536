export function loadNotificationsRequest() {
  return {
    type: '@notification/LOAD_NOTIFICATIONS_REQUEST',
  };
}
export function loadNotificationsSuccess(notifications) {
  return {
    type: '@notification/LOAD_NOTIFICATIONS_SUCCESS',
    payload: { notifications },
  };
}
export function loadNotificationsFailure() {
  return {
    type: '@notification/LOAD_NOTIFICATIONS_FAILURE',
  };
}
export function editNotificationRequest(data) {
  return {
    type: '@notification/EDIT_NOTIFICATION_REQUEST',
    payload: { data },
  };
}
export function editNotificationSuccess(notification) {
  return {
    type: '@notification/EDIT_NOTIFICATION_SUCCESS',
    payload: { notification },
  };
}
export function editNotificationFailure() {
  return {
    type: '@notification/EDIT_NOTIFICATION_FAILURE',
  };
}
