import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import pt from 'date-fns/locale/pt-BR';

import { subDays, getDay, addDays, format, isBefore } from 'date-fns';

import { MdReceipt, MdLocalPrintshop } from 'react-icons/md';

import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import {
  Container,
  Navigation,
  Reports,
  Rep,
  Content,
  Dates,
  Menu,
} from './styles';
import api from '~/services/api';

import Modal from '~/components/Modal';
import ReactDatePicker from '~/components/ReactDatePicker';
import ReactSelect from '~/components/ReactSelect';

const fileTypes = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export default function Report() {
  const [modalOpen, setModalOpen] = useState(false);
  const [reportType, setReportType] = useState('default');
  const [professionals, setProfessionals] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const data = {
    start_date: subDays(new Date(), getDay(new Date())),
    end_date: addDays(new Date(), 6 - getDay(new Date())),
  };

  const schema = Yup.object().shape({
    start_date: Yup.string().required('Data de início é obrigatória'),
    end_date: Yup.string().required('Data de fim é obrigatória'),
    canceled_at: Yup.string().required('Esse campo é obrigatório'),
    type: Yup.string().required('Informe o tipo'),
    professional_id: Yup.string().test(
      'professional_id_test',
      'Informe o profissional',
      value => {
        const isValid = reportType === 'professional' && !value;
        return !isValid;
      }
    ),
    agreement_id: Yup.string(),
  });

  const options = [
    {
      id: 'true',
      title: 'Cancelado',
    },
    {
      id: 'false',
      title: 'Confirmado',
    },
    {
      id: 'undefined',
      title: 'Todos',
    },
  ];

  async function onSubmit(dataForm) {
    const {
      canceled_at,
      start_date,
      end_date,
      type,
      professional_id,
      agreement_id,
    } = dataForm;
    const startDate = format(new Date(start_date), 'yyyy-MM-dd');
    const endDate = format(new Date(end_date), 'yyyy-MM-dd');
    if (isBefore(new Date(endDate), new Date(startDate))) {
      toast.warn('A data de início não pode ser maior que o final!');
    } else {
      const response = await api.get(`appointments/export`, {
        responseType: 'arraybuffer',
        params: {
          start_date: startDate,
          end_date: endDate,
          canceled_at,
          type,
          professional_id,
          agreement_id,
        },
      });
      const file = new Blob([response.data], {
        type: fileTypes[type],
      });
      const fileURL = URL.createObjectURL(file);
      if (type === 'pdf') {
        setModalOpen(false);
        window.open(fileURL);
      } else {
        const a = document.createElement('a');
        a.style = 'display: none';
        a.href = fileURL;
        a.download = `Agendamentos.${type}`;
        setModalOpen(false);
        a.click();
      }
      URL.revokeObjectURL(fileURL);
    }
  }
  async function loadProfessionals() {
    try {
      const response = await api.get('professionals');
      const data = response.data.professionals.map(p => ({
        id: p.id,
        title: p.name,
        color: p.color,
      }));

      setProfessionals(data);
    } catch (error) {
      console.tron.log(error);
    }
  }
  async function loadAgreements() {
    const response = await api.get('agreements');
    const agreementsData = response.data.map(agreement => ({
      id: agreement.id,
      title: agreement.description,
    }));
    setAgreements(agreementsData);
  }

  useEffect(() => {
    loadProfessionals();
    loadAgreements();
  }, []);

  const openModal = type => {
    setReportType(type);
    setModalOpen(true);
  };

  return (
    <Container>
      {modalOpen && (
        <Modal size="big" close={() => setModalOpen(false)}>
          <Form schema={schema} initialData={data} onSubmit={onSubmit}>
            <ReactSelect
              name="canceled_at"
              label="Opções"
              placeholder="Selecione uma das opções para gerar o relatório"
              options={options}
              isClearable
            />
            {reportType === 'professional' && (
              <ReactSelect
                name="professional_id"
                label="Profissional"
                placeholder="Selecione o profissional"
                options={professionals}
              />
            )}
            {reportType === 'agreement' && (
              <ReactSelect
                name="agreement_id"
                label="Convênio"
                placeholder="Selecione o convênio"
                options={agreements}
                isClearable
              />
            )}
            <ReactSelect
              name="type"
              label="Tipo"
              placeholder="Tipo de relatório"
              options={[
                {
                  id: 'pdf',
                  title: 'Pdf',
                },
                {
                  id: 'xlsx',
                  title: 'Excel',
                },
                {
                  id: 'csv',
                  title: 'CSV',
                },
              ]}
              isClearable
            />
            <Dates>
              <div>
                <ReactDatePicker
                  label="Início"
                  id="start_date"
                  name="start_date"
                  locale={pt}
                  dateFormat="dd/MM/yyyy"
                  mask="99/99/9999"
                />
              </div>
              <div>
                <ReactDatePicker
                  label="Fim"
                  id="end_date"
                  name="end_date"
                  locale={pt}
                  dateFormat="dd/MM/yyyy"
                  mask="99/99/9999"
                />
              </div>
            </Dates>
            <hr />
            <button type="submit" id="default">
              <MdLocalPrintshop size={24} color="#fff" />
              <p>Gerar</p>
            </button>
          </Form>
        </Modal>
      )}
      <Navigation>
        <Link to="/charts">Gráficos</Link>
      </Navigation>
      <Reports>
        <Rep>
          <Content>
            <strong>Relatório de Agendamentos</strong>
            <Menu>
              <button onClick={() => openModal('default')} type="button">
                <MdReceipt size={22} color="" />
              </button>
            </Menu>
          </Content>
        </Rep>
        <Rep>
          <Content>
            <strong>Relatório de Agendamentos por profissional</strong>
            <Menu>
              <button onClick={() => openModal('professional')} type="button">
                <MdReceipt size={22} color="" />
              </button>
            </Menu>
          </Content>
        </Rep>
        <Rep>
          <Content>
            <strong>Relatório de Agendamentos por convênio</strong>
            <Menu>
              <button onClick={() => openModal('agreement')} type="button">
                <MdReceipt size={22} color="" />
              </button>
            </Menu>
          </Content>
        </Rep>
      </Reports>
    </Container>
  );
}
