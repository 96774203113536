import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  max-width: 600px;
  margin: 50px auto;
  @media only screen and (max-width: 640px) {
    padding: 0 15px;
  }
  div#load {
    ${props =>
      props.load &&
      css`
        svg {
          animation: ${rotate} 2s linear infinite;
        }
      `}
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &::placeholder {
        color: #333;
      }
    }

    input#color {
      background: none;
      width: 80px;
      border-radius: 4px;
      padding: 0 15px;
    }

    hr {
      border: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      margin: 10px 0 20px;
    }

    span {
      color: #ffcb08;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      height: 44px;
      background: #f47920;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);
      p {
        margin-left: 5px;
      }

      &:hover {
        background: ${darken(0.08, '#f47920')};
      }

      ${props =>
        props.loading &&
        css`
          svg {
            animation: ${rotate} 2s linear infinite;
          }
        `}
    }
    > button#cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      height: 44px;
      background: #dc3545;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background(0.2s);
      p {
        margin-left: 5px;
      }

      &:hover {
        background: ${darken(0.08, '#dc3545')};
      }
    }

    label {
      color: #f47920;
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 5px;
    }

    select {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;
    }
  }
`;
