export default [
  {
    id: 'PARTICULAR',
    title: 'PARTICULAR',
  },
  {
    id: 'CASSEMS',
    title: 'CASSEMS',
  },
  {
    id: 'UNIMED',
    title: 'UNIMED',
  },
];
