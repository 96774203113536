import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Menu, Content, Profile } from './styles';

import Notifications from '~/components/Notifications';
import MenuRight from '~/components/MenuRight';
import logo from '~/assets/images/logo.png';

export default function Header() {
  const profile = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content>
        <nav>
          <Link to="/">
            <img src={logo} alt="Unimed Três Lagoas" />
          </Link>
        </nav>

        <Menu>
          <Link to="/dashboard">
            <li>Agenda</li>
          </Link>

          <Link to="/patients">
            <li>Pacientes</li>
          </Link>

          <Link to="/professionals">
            <li>Profissionais</li>
          </Link>

          <Link to="/reports">
            <li>Relatórios</li>
          </Link>
        </Menu>

        <aside>
          <MenuRight />
          <Notifications />
          <Profile>
            <div>
              <strong>{profile && profile.name}</strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <img
              src={
                profile &&
                (profile.avatar
                  ? profile.avatar.url
                  : `https://ui-avatars.com/api/?name=${profile.name}&color=00995d`)
              }
              alt={profile && profile.name}
            />
            {/* <MdArrowDropDown color="#fff" size={22} /> */}
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
