import React, { useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

import { useField } from '@rocketseat/unform';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function ReactSelect({
  name,
  label,
  multiple,
  loadOptions,
  defaultOptions,
  ...rest
}) {
  const compSelectRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: compSelectRef.current,
      path: 'select.state.value.value',
      clearValue: selectRef => {
        selectRef.select.clearValue();
      },
      getValue: ref => {
        if (multiple) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(option => option.value);
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, multiple, registerField, rest.isMulti]);

  function getDefaultValue() {
    if (!defaultValue) return null;

    if (!multiple) {
      return defaultOptions.find(option => option.value === defaultValue);
    }

    return defaultOptions.filter(option => defaultValue.includes(option.value));
  }

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <AsyncSelect
        name={fieldName}
        aria-label={fieldName}
        isMulti={multiple}
        defaultValue={getDefaultValue()}
        ref={compSelectRef}
        getOptionValue={option => option.value}
        loadOptions={loadOptions}
        {...rest}
      />

      {error && <span>{error}</span>}
    </Container>
  );
}

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiple: PropTypes.bool,
  loadOptions: PropTypes.func.isRequired,
};

ReactSelect.defaultProps = {
  multiple: false,
};
