export function createAppointmentRequest(data) {
  return {
    type: '@appointment/CREATE_APPOINTMENT_REQUEST',
    payload: { data },
  };
}
export function createAppointmentSuccess(appointment) {
  return {
    type: '@appointment/CREATE_APPOINTMENT_SUCCESS',
    payload: { appointment },
  };
}
export function createAppointmentFailure() {
  return {
    type: '@appointment/CREATE_APPOINTMENT_FAILURE',
  };
}
export function editAppointmentRequest(data) {
  return {
    type: '@appointment/EDIT_APPOINTMENT_REQUEST',
    payload: { data },
  };
}
export function editAppointmentSuccess(appointment) {
  return {
    type: '@appointment/EDIT_APPOINTMENT_SUCCESS',
    payload: { appointment },
  };
}
export function editAppointmentFailure() {
  return {
    type: '@appointment/EDIT_APPOINTMENT_FAILURE',
  };
}
export function deleteAppointmentRequest(data) {
  return {
    type: '@appointment/DELETE_APPOINTMENT_REQUEST',
    payload: { data },
  };
}
export function deleteAppointmentSuccess(appointment) {
  return {
    type: '@appointment/DELETE_APPOINTMENT_SUCCESS',
    payload: { appointment },
  };
}
export function deleteAppointmentFailure() {
  return {
    type: '@appointment/DELETE_APPOINTMENT_FAILURE',
  };
}
export function loadAppointmentsRequest(data) {
  return {
    type: '@appointment/LOAD_APPOINTMENTS_REQUEST',
    payload: { data },
  };
}
export function loadAppointmentsSuccess(appointments) {
  return {
    type: '@appointment/LOAD_APPOINTMENTS_SUCCESS',
    payload: { appointments },
  };
}
export function loadAppointmentsFailure() {
  return {
    type: '@appointment/LOAD_APPOINTMENTS_FAILURE',
  };
}
