import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { FaSpinner } from 'react-icons/fa';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '~/assets/images/logo.png';
// import { Container } from './styles';
const schema = Yup.object().shape({
  login: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ login, password }) {
    dispatch(signInRequest(login, password));
  }
  return (
    <>
      <img src={logo} alt="Agenda Unimed" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="login" placeholder="Seu login" />
        <Input name="password" type="password" placeholder="Sua senha" />

        <button type="submit">
          {loading ? <FaSpinner color="#fff" size={22} /> : 'Acessar'}
        </button>
      </Form>
    </>
  );
}
