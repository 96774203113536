import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import pt from 'date-fns/locale/pt-BR'; // eslint-disable-line
import PropTypes from 'prop-types';
import ReactSelect from '~/components/ReactSelect';

import history from '~/services/history';

import {
  editAgreementRequest,
  loadAgreementRequest,
} from '~/store/modules/agreement/actions';

import { Container } from '../styles';
import tableValues from '~/utils/tableValues';

const schema = Yup.object().shape({
  description: Yup.string().required('A descrição é obrigatória'),
  table_name: Yup.string().required('Informe a tabela de valor'),
});

export default function Agreement({ match }) {
  const { agreement, loading } = useSelector(state => state.agreement);
  const dispatch = useDispatch();

  function handleSubmit(data) {
    data.id = agreement.id;
    dispatch(editAgreementRequest(data));
  }

  useEffect(() => {
    async function loadAgreement() {
      dispatch(loadAgreementRequest(match.params.agreement));
    }

    loadAgreement();
  }, []); // eslint-disable-line

  return (
    <Container loading={loading}>
      {!loading && (
        <Form initialData={agreement} schema={schema} onSubmit={handleSubmit}>
          <Input
            name="description"
            placeholder="Informe a descrição"
            label="Descrição"
          />
          <ReactSelect
            name="table_name"
            label="Tabelas de valores"
            placeholder="Informe o tabela de valor"
            options={tableValues}
          />

          <button type="submit">
            {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
          </button>
          <button
            id="cancel"
            type="button"
            onClick={() => history.push('/agreements')}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Container>
  );
}

Agreement.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      agreement: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
