export function createProfessionalRequest(data) {
  return {
    type: '@professional/CREATE_PROFESSIONAL_REQUEST',
    payload: { data },
  };
}
export function createProfessionalSuccess(professional) {
  return {
    type: '@professional/CREATE_PROFESSIONAL_SUCCESS',
    payload: { professional },
  };
}
export function createProfessionalFailure() {
  return {
    type: '@professional/CREATE_PROFESSIONAL_FAILURE',
  };
}
export function editProfessionalRequest(data) {
  return {
    type: '@professional/EDIT_PROFESSIONAL_REQUEST',
    payload: { data },
  };
}
export function editProfessionalSuccess(professional) {
  return {
    type: '@professional/EDIT_PROFESSIONAL_SUCCESS',
    payload: { professional },
  };
}
export function editProfessionalFailure() {
  return {
    type: '@professional/EDIT_PROFESSIONAL_FAILURE',
  };
}
