import produce from 'immer';

const INITIAL_STATE = {
  professional: null,
  loading: 0,
};

export default function professional(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@professional/CREATE_PROFESSIONAL_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@professional/CREATE_PROFESSIONAL_SUCCESS': {
        draft.professional = action.payload.professional;
        draft.loading = 0;
        break;
      }
      case '@professional/CREATE_PROFESSIONAL_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@professional/EDIT_PROFESSIONAL_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@professional/EDIT_PROFESSIONAL_SUCCESS': {
        draft.professional = action.payload.professional;
        draft.loading = 0;
        break;
      }
      case '@professional/EDIT_PROFESSIONAL_FAILURE': {
        draft.loading = 0;
        break;
      }
      default:
    }
  });
}
