import produce from 'immer';

const INITIAL_STATE = {
  appointment: null,
  appointments: [],
  loading: 0,
};

export default function appointment(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@appointment/CREATE_APPOINTMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@appointment/CREATE_APPOINTMENT_SUCCESS': {
        draft.appointment = action.payload.appointment;
        draft.appointments.push(draft.appointment[0]);
        draft.loading = 0;
        break;
      }
      case '@appointment/CREATE_APPOINTMENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@appointment/EDIT_APPOINTMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@appointment/EDIT_APPOINTMENT_SUCCESS': {
        draft.appointment = action.payload.appointment;
        const index = draft.appointments.findIndex(
          e => e.id === draft.appointment[0].id
        );
          draft.appointments[index] = draft.appointment[0]; // eslint-disable-line
        draft.loading = 0;
        break;
      }
      case '@appointment/EDIT_APPOINTMENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@appointment/DELETE_APPOINTMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@appointment/DELETE_APPOINTMENT_SUCCESS': {
        draft.appointment = action.payload.appointment;
        const index = draft.appointments.findIndex(
          e => e.id === draft.appointment.id
        );
        draft.appointments.splice(index, 1);
        draft.loading = 0;
        break;
      }
      case '@appointment/DELETE_APPOINTMENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@appointment/LOAD_APPOINTMENTS_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@appointment/LOAD_APPOINTMENTS_SUCCESS': {
        draft.appointments = action.payload.appointments;
        draft.loading = 0;
        break;
      }
      case '@appointment/LOAD_APPOINTMENTS_FAILURE': {
        draft.loading = 0;
        break;
      }
      default:
    }
  });
}
