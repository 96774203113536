import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import appointment from './appointment/sagas';
import patient from './patient/sagas';
import professional from './professional/sagas';
import notification from './notification/sagas';
import agreement from './agreement/sagas';
import wait from './wait/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    appointment,
    patient,
    professional,
    notification,
    agreement,
    wait,
  ]);
}
