import { takeLatest, call, put, all } from 'redux-saga/effects';

import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  createWaitSuccess,
  createWaitFailure,
  loadWaitRequest,
  loadWaitSuccess,
  loadWaitFailure,
  deleteWaitSuccess,
  deleteWaitFailure,
} from './actions';

import api from '~/services/api';

export function* loadWait() {
  try {
    const response = yield call(api.get, 'wait');
    const data = response.data.map(wait => ({
      id: wait.id,
      title: wait.patient.name,
      professional_id: wait.professional.id,
      patient_id: wait.patient.id,
      past: wait.past,
      doctor_name: wait.doctor_name,
      doctor_crm: wait.doctor_crm,
      description: wait.description,
      professional: {
        id: wait.professional.id,
        name: wait.professional.name,
        color: wait.professional.color,
      },
      patient: {
        id: wait.patient.id,
        name: wait.patient.name,
        agreement: {
          id: wait.patient.agreement.id,
          description: wait.patient.agreement.description,
        },
      },
      start: parseISO(wait.start_date),
      end: parseISO(wait.end_date),
    }));

    yield put(loadWaitSuccess(data));
  } catch (error) {
    toast.error(
      `Erro ao listar os pacientes na lista de espera ${error.message}`
    );
    yield put(loadWaitFailure());
  }
}

export function* createWait({ payload }) {
  try {
    const response = yield call(api.post, 'wait', payload.data);

    toast.success('Paciente adicionado à lista de espera!');
    yield put(createWaitSuccess(response.data));
    yield put(loadWaitRequest());
  } catch (error) {
    toast.error('Erro ao adicionar paciente à lista de espera!');
    yield put(createWaitFailure());
  }
}

export function* deleteWait({ payload }) {
  try {
    yield call(api.delete, `wait/${payload.data.id}`);

    toast.success('Paciente removido da fila de espera!');
    yield put(deleteWaitSuccess(payload.data));
  } catch (error) {
    toast.error('Erro ao remove paciente da fila de espera!');
    yield put(deleteWaitFailure());
  }
}

export default all([
  takeLatest('@wait/LOAD_WAIT_REQUEST', loadWait),
  takeLatest('@appointment/EDIT_APPOINTMENT_SUCCESS', loadWait),
  takeLatest('@wait/CREATE_WAIT_REQUEST', createWait),
  takeLatest('@wait/DELETE_WAIT_REQUEST', deleteWait),
]);
