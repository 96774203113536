import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
*{
margin: 0;
padding: 0;
outline: 0;
box-sizing: border-box;
}
*:focus{
  outline: 0;
}

html,body, #root{
   height : 100%;
}

body{
  background: #fff ;
  -webkit-font-smoothing: antialiased;
}

body, input, button{
  font: 14px 'Roboto',sans-serif;
}
a{
  text-decoration: none;
}

ul{
  list-style: none;
}

button {
  cursor: pointer;
}
`;
