import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import { Chart as ChartContent } from "react-google-charts";

import api from '~/services/api'


import { Container, Content, Navigation } from './styles';

import {FaSpinner} from 'react-icons/fa'

export default function Chart() {
  const [dataCanceled,setDataCanceled] = useState([]);
  const [dataNotCanceled,setDataNotCanceled] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    async function loadDataCanceled(){
      setLoading(true);
      const response = await api.get('graphic',{
        params: {
          type : 'canceled_at'
        }
      });
      const header = ['Mês','Quantidade'];
      const content = response.data.map((elem,i,array) => [
          elem.month,
          elem.quantity]);

      content.unshift(header)
      setDataCanceled(content);
      setLoading(false);
    }
    async function loadDataNotCanceled(){
      setLoading(true);
      const response = await api.get('graphic');
      const header = ['Mês','Quantidade'];
      const content =  response.data.map((elem,i,array) => [
          elem.month,
          elem.quantity
        ])
      content.unshift(header)
      setDataNotCanceled(content);
      setLoading(false);
    }
    loadDataCanceled();
    loadDataNotCanceled();
  },[])




  return (
    <Container>
      <Navigation>
        <Link to="/reports">
          Relatórios
        </Link>
      </Navigation>
        {loading
        ?
        (
          <FaSpinner size={25} />
        )
        :
        (

          <Content>
            <ChartContent
              width={'500px'}
              height={'300px'}
              chartType="Bar"
              data={dataNotCanceled}
              options={{ chart: {
                title: 'Quantidade de Agendamentos por mês',
              }}}
              // For tests
              rootProps={{ 'data-testid': '2' }}
            />
            <ChartContent
              width={'500px'}
              height={'300px'}
              chartType="Bar"
              data={dataCanceled}
              options={{ chart: {
                title: 'Quantidade de Agendamentos Cancelados por mês',
              }}}
              // For tests
              rootProps={{ 'data-testid': '2' }}
            />
          </Content>
        )}
    </Container>
  );
}
