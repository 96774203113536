import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.header`
  background: #00995d;
  padding: 0 40px;
`;
export const Content = styled.div`
  height: 68px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    cursor: pointer;
    padding: 5px;
    &:hover {
      background: ${darken(0.03, '#00995D')};
    }
  }

  nav {
    display: flex;
    align-items: center;
    img {
      padding-right: 140px;
      border-right: 1px solid #eee;
      height: 50px;
    }
  }

  @media (max-width : 990px) {
    nav{
    img{
      padding-right: 100px;
    }
    }
  }
  @media (max-width : 910px) {
    nav{
    img{
      padding-right: 50px;
    }
    }
  }
  @media (max-width : 860px) {
    nav{
    img{
      padding-right: 10px;
    }
    }
  }
  @media (max-width : 815px) {
    nav{
      display: none;
    }
  }

  aside {
    display: flex;
    align-items: center;
  }
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 1px;
  border-left: 1px solid #eee;

  div {
    margin-left: 20px;
    text-align: right;
    margin-right: 10px;
    color: #fff;
    strong {
      display: block;
      font-size: 16px;

    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #fff;
      &:hover {
        color: #f47920;
      }
    }
  }
  @media (max-width : 1150px) {
    div{
      strong{
        font-size: 13px;
      }
    }
  }
  @media (max-width : 1105px) {
    div{
      strong{
        font-size: 10px;
      }
    }
  }
  @media (max-width : 1063px) {
    div{
      strong{
        display: none;
      }
      }
    img{
      display: none;
    }
  }

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
`;

export const Menu = styled.ul`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  li {
    color: #fff;
    padding: 10px;
    border-radius: 2px;
    margin-right: 20px;

    &:hover {
      background: ${darken(0.03, '#00995D')};
    }
  }

  @media (max-width : 1020px) {
    font-size: 16px;
  }
  @media (max-width : 972px) {
    font-size: 14px;
  }


`;
