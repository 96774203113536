import produce from 'immer';

const INITIAL_STATE = {
  notification: null,
  notifications: [],
};

export default function notification(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@notification/LOAD_NOTIFICATIONS_REQUEST': {
        break;
      }
      case '@notification/LOAD_NOTIFICATIONS_SUCCESS': {
        draft.notifications = action.payload.notifications;
        break;
      }
      case '@notification/LOAD_NOTIFICATIONS_FAILURE': {
        break;
      }
      case '@notification/EDIT_NOTIFICATION_REQUEST': {
        break;
      }
      case '@notification/EDIT_NOTIFICATION_SUCCESS': {
        draft.notification = action.payload.notification;
        draft.notifications = draft.notifications.map(n =>
          n._id === draft.notification._id ? { ...n, read: true } : n
        );
        break;
      }
      case '@notification/EDIT_NOTIFICATION_FAILURE': {
        break;
      }
      default:
    }
  });
}
