import styled from 'styled-components';
import { darken } from 'polished';
import search from '~/assets/images/search.svg';

export const Container = styled.div`
  max-width: 900px;
  margin: 100px auto;

  > input:focus {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }

  input {
    width: 100%;
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 50px;
    color: #333;
    margin: 0 0 10px;
    background: rgba(0, 0, 0, 0.1) url(${search}) no-repeat 860px center;
    &::placeholder {
      color: #333;
    }
  }

  hr {
    border: 1px solid #999;
    margin: 10px 0 5px;
  }
`;

export const PatientTable = styled.table`
  width: 100%;
  thead th {
    color: #999;
    text-align: left;
    padding: 12px;
  }

  tbody td {

    padding: 7px;
    border-bottom: 2px solid #eee;
    button {
      border-radius: 3px;
      background: #f47920;
      color: #fff;

      &:hover {
        background: ${darken(0.08, '#f47920')};
      }
    }

    svg{
      &:hover{
        color: ${darken(0.08, '#f47920')};
        cursor: pointer;
      }
    }

    > a strong {
      text-decoration: underline;

      &:hover {
        color: #f47920;
      }
    }
  }

  strong {
    color: #333;
    display: block;
    >span{
      font-size: 10px;
    }
  }

  span {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: center;

    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
      padding: 6px;
      width: 50px;
    }
  }

  button {
    background: none;
    border: 0;
    padding: 6px;
  }
`;

export const Historic = styled.tr`
  strong {
    color: ${props => (props.cancelled) && '#999'};
    display: block;
  }
`;

export const SearchDates = styled.div`
  display: flex;
  justify-content: start;
  padding-bottom: 15px;
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    &:first-child{
      padding-right : 50px;
    }
  }
  div#button{
    display: flex;
    align-items: center;
    justify-content: center;
    >button{
      border-radius: 4px;
      margin-left: 25px;
      padding: 5px;
      border: none;
      background: #f47920;
      color: #fff;
      font-weight: bold;
      &:hover{
        background: ${darken(0.08, '#f47920')};
        cursor: pointer;
      }
    }
  }
`;
