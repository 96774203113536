import produce from 'immer';

const INITIAL_STATE = {
  wait: null,
  waits: [],
  loading: 0,
};

export default function wait(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@wait/CREATE_WAIT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@wait/CREATE_WAIT_SUCCESS': {
        draft.loading = 0;
        break;
      }
      case '@wait/CREATE_WAIT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@wait/LOAD_WAIT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@wait/LOAD_WAIT_SUCCESS': {
        draft.waits = action.payload.wait;
        draft.loading = 0;
        break;
      }
      case '@wait/LOAD_WAIT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@wait/EDIT_WAIT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@wait/EDIT_WAIT_SUCCESS': {
        draft.loading = 0;
        break;
      }
      case '@wait/EDIT_WAIT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@wait/DELETE_WAIT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@wait/DELETE_WAIT_SUCCESS': {
        draft.wait = action.payload.wait;
        const index = draft.waits.findIndex(e => e.id === draft.wait.id);
        draft.waits.splice(index, 1);
        draft.loading = 0;
        break;
      }
      case '@wait/DELETE_WAIT_FAILURE': {
        draft.loading = 0;
        break;
      }

      default:
    }
  });
}
