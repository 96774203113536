import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import appointment from './appointment/reducer';
import patient from './patient/reducer';
import professional from './professional/reducer';
import notification from './notification/reducer';
import agreement from './agreement/reducer';
import wait from './wait/reducer';

export default combineReducers({
  auth,
  user,
  appointment,
  patient,
  professional,
  notification,
  agreement,
  wait,
});
