import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import pt from 'date-fns/locale/pt-BR';
import axios from 'axios';
import { MdSearch } from 'react-icons/md';

import api from '~/services/api';
import history from '~/services/history';

import { createPatientRequest } from '~/store/modules/patient/actions';

import { Container } from './styles';
import ReactSelect from '~/components/ReactSelect';
import ReactDatePicker from '~/components/ReactDatePicker';
import Input from '~/components/Input';
import ModalDefault from '~/components/ModalDefault';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cpf: Yup.string().required('O CPF é obrigatório'),
  card: Yup.string(),
  email: Yup.string().email('Informe um email válido'),
  phone: Yup.string().required('O telefone é obrigatório'),
  cellphone: Yup.string(),
  birth: Yup.string().required('É necessário informar a data de nascimento'),
  agreement_id: Yup.string().required('O convênio é obrigatório'),
  cep: Yup.string().required('É necessário informar o CEP'),
  address: Yup.string().required('É necessário informar o logradouro'),
  number: Yup.string().required('É necessário informar o número'),
  district: Yup.string().required('É necessário informar o bairro'),
  uf: Yup.string().required('É necessário informar o estado'),
  city: Yup.string().required('É necessário informar a cidade'),
  complement: Yup.string(),
});

const schemaCep = Yup.object().shape({
  address: Yup.string().required('É necessário informar o logradouro'),
  uf: Yup.string().required('É necessário informar o estado'),
  city: Yup.string().required('É necessário informar a cidade'),
});

export default function Patient() {
  const loading = useSelector(state => state.patient.loading);
  const [agreements, setAgreements] = useState([]);
  const [cepList, setCepList] = useState([]);
  const [addressPatient, setAddressPatient] = useState({});
  const [modalCepOpen, setModalCepOpen] = useState(false);
  const dispatch = useDispatch();
  function handleSubmit(data) {
    const patient = {
      agreement_id: data.agreement_id,
      birth: data.birth,
      cellphone: data.cellphone.replace(/[^0-9]/g, ''),
      phone: data.phone.replace(/[^0-9]/g, ''),
      email: data.email,
      card: data.card.replace(/[^0-9]/g, ''),
      cpf: data.cpf.replace(/[^0-9]/g, ''),
      name: data.name,
      address: {
        cep: data.cep,
        address: data.address,
        number: data.number,
        district: data.district,
        uf: data.uf,
        city: data.city,
        complement: data.complement,
        ibge: addressPatient.ibge,
      },
    };

    dispatch(createPatientRequest(patient));
  }

  async function handleChangeCep(e) {
    const cep = e.target.value;

    if (cep.length === 8) {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
      setAddressPatient({
        address: response.data.logradouro,
        district: response.data.bairro,
        city: response.data.localidade,
        uf: response.data.uf,
        ibge: response.data.ibge,
      });
    }
  }

  const toggleModal = () => {
    setModalCepOpen(!modalCepOpen);
  };

  async function handleSearchCep(data) {
    const { city, uf, address } = data;
    const response = await axios.get(
      `https://viacep.com.br/ws/${uf}/${city}/${address}/json`
    );
    setCepList(response.data);
  }

  useEffect(() => {
    async function loadAgreements() {
      const response = await api.get('agreements');
      const data = response.data.map(agreement => ({
        id: agreement.id,
        title: agreement.description,
      }));
      setAgreements(data);
    }

    loadAgreements();
  }, []); // eslint-disable-line

  return (
    <Container loading={loading}>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          name="name"
          placeholder="Informe o nome do paciente"
          label="Nome"
        />

        <Input
          name="cpf"
          mask="999.999.999-99"
          placeholder="Informe o Cpf do paciente"
          label="CPF"
        />

        <Input
          name="card"
          placeholder="Informe a carteirinha do paciente"
          label="Carteirinha (0000 0000000000000)"
          mask="9999 9999999999999"
        />

        <ReactDatePicker
          id="birth"
          name="birth"
          mask="99/99/9999"
          label="Data de nascimento"
          locale={pt}
        />

        <hr />

        <Input
          name="email"
          type="email"
          placeholder="Informe o email do paciente"
          label="Email"
        />

        <Input
          name="phone"
          placeholder="Informe o número de telefone"
          label="Telefone"
          mask="(99) 9999-9999"
        />

        <Input
          name="cellphone"
          placeholder="Informe o número de celular"
          label="Celular"
          mask="(99) 99999-9999"
        />
        {/* <button
          type="button"
          id="btn-cep"
          onClick={() => setModalCepOpen(true)}
        >
          <MdSearch color="#fff" size={22} />
        </button> */}
        <Input
          name="cep"
          placeholder="Informe o cep"
          label="Cep"
          onChange={handleChangeCep}
        />

        <Input
          name="address"
          placeholder="Informe o logradouro"
          label="Logradouro"
          value={addressPatient.address}
        />

        <Input name="number" placeholder="Informe o número" label="Número" />

        <Input
          name="district"
          placeholder="Informe o bairro"
          label="Bairro"
          value={addressPatient.district}
        />

        <Input
          name="complement"
          placeholder="Informe o complemento"
          label="Complemento"
        />

        <Input
          name="city"
          placeholder="Informe a cidade"
          label="Cidade"
          value={addressPatient.city}
        />

        <Input
          name="uf"
          placeholder="Informe o estado"
          label="Estado"
          value={addressPatient.uf}
        />

        <hr />

        <ReactSelect
          name="agreement_id"
          label="Convênio"
          placeholder="Informe o convênio"
          options={agreements}
          isClearable
        />

        <hr />

        <button type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
        </button>

        <button
          id="cancel"
          type="button"
          onClick={() => history.push('/patients')}
        >
          Cancelar
        </button>
      </Form>
      {modalCepOpen && (
        <ModalDefault size="big" toggleModal={toggleModal} title="Buscar Cep">
          {cepList.length > 0 ? (
            cepList.map(cep => <h1>{cep.cep}</h1>)
          ) : (
            <>
              <Form schema={schemaCep} onSubmit={handleSearchCep}>
                <Input
                  label="Cidade"
                  name="city"
                  placeholder="Informe a cidade"
                />
                <Input
                  label="Estado"
                  name="uf"
                  placeholder="Informe o estado"
                />
                <Input label="Rua" name="address" placeholder="Informe a Rua" />
                <button type="submit">
                  Buscar
                  <MdSearch color="#FFF" size={22} />
                </button>
              </Form>
            </>
          )}
        </ModalDefault>
      )}
    </Container>
  );
}
