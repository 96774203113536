import { takeLatest, call, put, all } from 'redux-saga/effects';
import { cpf as libCpf } from 'cpf-cnpj-validator';

import { toast } from 'react-toastify';
import {
  createPatientSuccess,
  createPatientFailure,
  editPatientSuccess,
  editPatientFailure,
} from './actions';

import api from '~/services/api';

export function* createPatient({ payload }) {
  try {
    const { cpf } = payload.data;
    if (!libCpf.isValid(cpf)) {
      toast.error('CPF inválido');
      yield put(createPatientFailure());
      return;
    }
    const response = yield call(api.post, 'patients', payload.data);
    toast.success('Paciente cadastrado com sucesso!');
    yield put(createPatientSuccess(response.data));
  } catch (error) {
    toast.error('Erro ao cadastrar paciente!');
    yield put(createPatientFailure());
  }
}

export function* editPatient({ payload }) {
  try {
    const response = yield call(
      api.put,
      `patients/${payload.data.id}`,
      payload.data
    );
    toast.success('Paciente atualizado com successo!');
    yield put(editPatientSuccess(response.data));
  } catch (error) {
    toast.error('Erro ao atualizar paciente!');
    yield put(editPatientFailure());
  }
}

export default all([
  takeLatest('@patient/CREATE_PATIENT_REQUEST', createPatient),
  takeLatest('@patient/EDIT_PATIENT_REQUEST', editPatient),
]);
