import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Container, ProfessionalTable, Color } from './styles';

import api from '~/services/api';

export default function Professional() {
  const [professionals, setProfessionals] = useState([]);

  async function searchProfessional(e) {
    const response = await api.get('professionals', {
      params: {
        search: e.target.value,
      },
    });
    setProfessionals(response.data);
  }

  useEffect(() => {
    async function loadProfessionals() {
      const response = await api.get('professionals');
      setProfessionals(response.data.professionals);
    }
    loadProfessionals();
  }, []);

  return (
    <Container>
      <input
        placeholder="Digite o nome,email ou especialidade..."
        onChange={searchProfessional}
      />
      <hr />
      <ProfessionalTable>
        <thead>
          <tr>
            <th>NOME</th>
            <th>EMAIL</th>
            <th>ESPECIALIDADE</th>
            <th>COR AGENDA</th>
          </tr>
        </thead>
        <tbody>
          {professionals.map(professional => (
            <tr key={professional.id}>
              <td>
                <Link to={`professional/${professional.id}/edit`}>
                  <strong>{professional.name}</strong>
                </Link>
              </td>
              <td>
                <strong>{professional.email}</strong>
              </td>
              <td>
                <strong>{professional.specialty.description}</strong>
              </td>
              <td>
                <Color color={professional.color} />
              </td>
            </tr>
          ))}
        </tbody>
      </ProfessionalTable>
    </Container>
  );
}
