import { takeLatest, call, put, all } from 'redux-saga/effects';
import { format, parseISO } from 'date-fns';

import { toast } from 'react-toastify';
import {
  createAppointmentSuccess,
  createAppointmentFailure,
  editAppointmentSuccess,
  editAppointmentFailure,
  deleteAppointmentSuccess,
  deleteAppointmentFailure,
  loadAppointmentsSuccess,
  loadAppointmentsFailure,
} from './actions';

import { loadNotificationsRequest } from '../notification/actions';

import api from '~/services/api';

export function* createAppointment({ payload }) {
  try {
    const response = yield call(api.post, 'appointments', payload.data);
    const appointment = response.data;
    const data = [
      {
        id: appointment.id,
        title: appointment.patient.name,
        professional_id: appointment.professional.id,
        patient_id: appointment.patient.id,
        past: appointment.past,
        doctor_name: appointment.doctor_name,
        doctor_crm: appointment.doctor_crm,
        description: appointment.description,
        confirmed: appointment.confirmed,
        service_code: appointment.service_code,
        service_name: appointment.service_name,
        agreement_id: appointment.agreement_id,
        service_value: appointment.service_value,
        payment_type: appointment.payment_type,
        professional: {
          id: appointment.professional.id,
          name: appointment.professional.name,
          color: appointment.professional.color,
        },
        patient: {
          id: appointment.patient.id,
          name: appointment.patient.name,
        },
        start: parseISO(appointment.start_date),
        end: parseISO(appointment.end_date),
      },
    ];
    toast.success('Agendamento criado com sucesso!');
    yield put(createAppointmentSuccess(data));
    yield put(loadNotificationsRequest());
  } catch (error) {
    toast.error('Erro ao criar o agendamento!');
    yield put(createAppointmentFailure());
  }
}

export function* editAppointment({ payload }) {
  try {
    const response = yield call(
      api.put,
      `appointments/${payload.data.id}`,
      payload.data
    );
    const appointment = response.data.appointmentUpdated;
    const data = [
      {
        id: appointment.id,
        title: appointment.patient.name,
        professional_id: appointment.professional.id,
        patient_id: appointment.patient.id,
        past: appointment.past,
        doctor_name: appointment.doctor_name,
        doctor_crm: appointment.doctor_crm,
        description: appointment.description,
        confirmed: appointment.confirmed,
        service_code: appointment.service_code,
        service_name: appointment.service_name,
        agreement_id: appointment.agreement_id,
        service_value: appointment.service_value,
        payment_type: appointment.payment_type,
        professional: {
          id: appointment.professional.id,
          name: appointment.professional.name,
          color: appointment.professional.color,
        },
        patient: {
          id: appointment.patient.id,
          name: appointment.patient.name,
        },
        start: parseISO(appointment.start_date),
        end: parseISO(appointment.end_date),
      },
    ];
    toast.success('Agendamento editado com sucesso!');
    yield put(editAppointmentSuccess(data));
  } catch (error) {
    toast.error(`Erro ao editar o agendamento! ${error.message}`);
    yield put(editAppointmentFailure());
  }
}

export function* deleteAppointment({ payload }) {
  try {
    const response = yield call(api.delete, `appointments/${payload.data.id}`);
    toast.success('Agendamento deletado com sucesso!');
    yield put(deleteAppointmentSuccess(response.data));
    yield put(loadNotificationsRequest());
  } catch (error) {
    toast.error('Não é possível deletar esse agendamento!');
    yield put(deleteAppointmentFailure());
  }
}

export function* loadAppointments({ payload }) {
  try {
    const { start_date, end_date, professional_id } = payload.data;
    const compareStartDate = format(start_date, 'yyyy-MM-dd');
    const compareEndDate = format(end_date, 'yyyy-MM-dd');
    const response = yield call(
      api.get,
      `schedule?start_date=${compareStartDate}&end_date=${compareEndDate}&professional_id=${professional_id}`
    );

    const data = response.data.map(appointment => ({
      id: appointment.id,
      title: appointment.patient.name,
      professional_id: appointment.professional_id,
      patient_id: appointment.patient_id,
      past: appointment.past,
      doctor_name: appointment.doctor_name,
      doctor_crm: appointment.doctor_crm,
      description: appointment.description,
      confirmed: appointment.confirmed,
      service_code: appointment.service_code,
      service_name: appointment.service_name,
      service_value: appointment.service_value,
      agreement_id: appointment.agreement_id,
      payment_type: appointment.payment_type,
      professional: {
        id: appointment.professional_id,
        name: appointment.professional.name,
        color: appointment.professional.color,
      },
      patient: {
        id: appointment.patient_id,
        name: appointment.patient.name,
      },
      start: parseISO(appointment.start_date),
      end: parseISO(appointment.end_date),
    }));

    yield put(loadAppointmentsSuccess(data));
  } catch (error) {
    toast.error(`Erro ao listar agendamentos!`);
    yield put(loadAppointmentsFailure());
  }
}

export default all([
  takeLatest('@appointment/CREATE_APPOINTMENT_REQUEST', createAppointment),
  takeLatest('@appointment/EDIT_APPOINTMENT_REQUEST', editAppointment),
  takeLatest('@appointment/DELETE_APPOINTMENT_REQUEST', deleteAppointment),
  takeLatest('@appointment/LOAD_APPOINTMENTS_REQUEST', loadAppointments),
]);
