import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { MdAddCircle } from 'react-icons/md';
import { Container, AgreementTable } from './styles';

import api from '~/services/api';

export default function Agreement() {
  const [agreements, setAgreements] = useState([]);

  async function searchAgreement(e) {
    const response = await api.get('agreements', {
      params: {
        search: e.target.value,
      },
    });
    setAgreements(response.data);
  }

  useEffect(() => {
    async function loadAgreements() {
      const response = await api.get('agreements');
      setAgreements(response.data);
    }
    loadAgreements();
  }, []);

  return (
    <Container>
      <div id="btn">
        <button type="button">
          <Link to="/agreement">
            <MdAddCircle size={24} />
            Novo Convênio
          </Link>
        </button>
      </div>
      <input placeholder="Digite a descrição..." onChange={searchAgreement} />
      <hr />
      <AgreementTable>
        <thead>
          <tr>
            <th>DESCRIÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {agreements.map(agreement => (
            <tr key={agreement.id}>
              <td>
                <Link to={`agreement/${agreement.id}/edit`}>
                  <strong>{agreement.description}</strong>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </AgreementTable>
    </Container>
  );
}
