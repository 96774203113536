import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  createAgreementSuccess,
  createAgreementFailure,
  editAgreementSuccess,
  editAgreementFailure,
  loadAgreementSuccess,
  loadAgreementFailure,
} from './actions';

import api from '~/services/api';
import history from '~/services/history';

export function* createAgreement({ payload }) {
  try {
    const response = yield call(api.post, 'agreements', payload.data);

    toast.success('Convênio cadastrado com sucesso!');
    yield put(createAgreementSuccess(response.data));
    history.push('/agreements');
  } catch (error) {
    toast.error('Erro ao cadastrar o convênio!');
    yield put(createAgreementFailure());
  }
}

export function* editAgreement({ payload }) {
  try {
    const response = yield call(
      api.put,
      `agreements/${payload.data.id}`,
      payload.data
    );
    const agreement = response.data;

    toast.success('Convênio editado com sucesso!');
    yield put(editAgreementSuccess(agreement));
    history.push('/agreements');
  } catch (error) {
    toast.error('Erro ao editar o convênio!');
    yield put(editAgreementFailure());
  }
}

export function* loadAgreement({ payload }) {
  try {
    const response = yield call(api.get, 'agreements', {
      params: {
        id: payload.data,
      },
    });
    const agreement = response.data;

    yield put(loadAgreementSuccess(agreement));
  } catch (error) {
    toast.error('Erro ao carregar o convênio!');
    yield put(loadAgreementFailure());
  }
}

export default all([
  takeLatest('@agreement/CREATE_AGREEMENT_REQUEST', createAgreement),
  takeLatest('@agreement/EDIT_AGREEMENT_REQUEST', editAgreement),
  takeLatest('@agreement/LOAD_AGREEMENT_REQUEST', loadAgreement),
]);
