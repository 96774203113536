import React, { useState, useEffect, useMemo } from 'react';
import { MdNotifications } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadNotificationsRequest,
  editNotificationRequest,
} from '~/store/modules/notification/actions';

import {
  Container,
  Badge,
  NotificationList,
  Scroll,
  Notification,
} from './styles';

export default function Notifications() {
  const [visible, setVisible] = useState(false);
  // const [notifications, setNotifications] = useState([]);
  const { notifications } = useSelector(state => state.notification);
  const closeMenu = () => {
    setVisible(false);
    document.removeEventListener('click', closeMenu);
  };

  const showMenu = () => {
    if (visible) return;
    setVisible(true);

    document.addEventListener('click', closeMenu);
  };

  const dispatch = useDispatch();

  const hasUnread = useMemo(
    () => !!notifications.find(notification => notification.read === false),
    [notifications]
  );

  useEffect(() => {
    async function loadNotifications() {
      dispatch(loadNotificationsRequest());
    }

    loadNotifications();
  }, []); // eslint-disable-line

  async function handleMarkAsRead(notification) {
    dispatch(editNotificationRequest(notification));
  }

  return (
    <Container>
      <Badge onClick={showMenu} hasUnread={hasUnread}>
        <MdNotifications color="#FFF" size={24} />
      </Badge>

      <NotificationList visible={visible}>
        <Scroll>
          {notifications.map(notification => (
            <Notification unread={!notification.read} key={notification._id}>
              <p>{notification.content}</p>
              <time>{notification.timeDistance}</time>
              {!notification.read && (
                <button
                  type="button"
                  onClick={() => handleMarkAsRead(notification)}
                >
                  Marcar como lida
                </button>
              )}
            </Notification>
          ))}
        </Scroll>
      </NotificationList>
    </Container>
  );
}
