import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';

import { FaSpinner } from 'react-icons/fa';

import { updateProfileRequest } from '~/store/modules/user/actions';
import { signOut } from '~/store/modules/auth/actions';

import AvatarInput from './AvatarInput';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector(state => state.user);

  function handleSubmit(data) {
    const dt = {
      ...data,
      id: profile.id,
    };
    dispatch(updateProfileRequest(dt));
  }

  function handleSignOut() {
    dispatch(signOut());
  }
  return (
    <Container loading={loading}>
      <Form initialData={profile} onSubmit={handleSubmit}>
        <AvatarInput name="avatar_id" name_user={profile.name} />

        <Input name="name" placeholder="Nome completo" />
        <Input name="email" type="email" placeholder="Seu endereço de email" />
        <hr />

        <Input
          type="password"
          name="oldPassword"
          placeholder="Sua senha atual"
        />
        <Input type="password" name="password" placeholder="Nova senha" />
        <Input
          type="password"
          name="confirmPassword"
          placeholder="Confirmação de senha"
        />

        <button type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Atualizar perfil'}
        </button>
      </Form>
      <button type="button" onClick={handleSignOut}>
        Sair do sistema
      </button>
    </Container>
  );
}
