import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  createProfessionalSuccess,
  createProfessionalFailure,
  editProfessionalSuccess,
  editProfessionalFailure,
} from './actions';

import api from '~/services/api';

export function* createProfessional({ payload }) {
  try {
    const response = yield call(api.post, 'professionals', payload.data);
    toast.success('Profissional cadastrado com sucesso!');
    yield put(createProfessionalSuccess(response.data));
  } catch (error) {
    toast.error('Erro ao cadastrar profissional!');
    yield put(createProfessionalFailure());
  }
}
export function* editProfessional({ payload }) {
  try {
    const response = yield call(
      api.put,
      `professionals/${payload.data.id}`,
      payload.data
    );
    toast.success('Profissional atualizado com sucesso!');
    yield put(editProfessionalSuccess(response.data));
  } catch (error) {
    toast.error('Erro ao atualizar dados do profissional!');
    yield put(editProfessionalFailure());
  }
}

export default all([
  takeLatest('@professional/CREATE_PROFESSIONAL_REQUEST', createProfessional),
  takeLatest('@professional/EDIT_PROFESSIONAL_REQUEST', editProfessional),
]);
