export function createPatientRequest(data) {
  return {
    type: '@patient/CREATE_PATIENT_REQUEST',
    payload: { data },
  };
}
export function createPatientSuccess(patient) {
  return {
    type: '@patient/CREATE_PATIENT_SUCCESS',
    payload: { patient },
  };
}
export function createPatientFailure() {
  return {
    type: '@patient/CREATE_PATIENT_FAILURE',
  };
}
export function editPatientRequest(data) {
  return {
    type: '@patient/EDIT_PATIENT_REQUEST',
    payload: { data },
  };
}
export function editPatientSuccess(patient) {
  return {
    type: '@patient/EDIT_PATIENT_SUCCESS',
    payload: { patient },
  };
}
export function editPatientFailure() {
  return {
    type: '@patient/EDIT_PATIENT_FAILURE',
  };
}
