import React, { useEffect, useState } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';

import api from '~/services/api';
import history from '~/services/history';

import { createProfessionalRequest } from '~/store/modules/professional/actions';

import { Container } from './styles';
import ReactSelect from '~/components/ReactSelect';
import Input from '~/components/Input';
import SignatureInput from './SignatureInput';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  full_name: Yup.string().required('O nome completo é obrigatório'),
  email: Yup.string().email('Informe um email válido'),
  color: Yup.string().required('Informe a cor da agenda do profissional'),
  specialty_id: Yup.string().required('A especialidade é obrigatória'),
  signature_id: Yup.number(),
  crefito: Yup.string().required('O crefito é obrigatório'),
});

export default function Professional() {
  const loading = useSelector(state => state.professional.loading);
  const [specialties, setSpecialties] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadSpecialties() {
      const response = await api.get('specialties');
      const data = response.data.specialties.map(specialty => ({
        id: specialty.id,
        title: specialty.description,
      }));
      setSpecialties(data);
    }
    loadSpecialties();
  }, []); // eslint-disable-line
  function handleSubmit(data) {
    dispatch(createProfessionalRequest(data));
  }
  return (
    <Container loading={loading}>
      <Form onSubmit={handleSubmit} schema={schema}>
        <Input
          name="name"
          placeholder="Informe o primeiro nome"
          label="Primeiro Nome"
        />

        <Input
          name="full_name"
          placeholder="Informe o nome completo"
          label="Nome Completo"
        />

        <Input
          name="email"
          type="email"
          placeholder="Informe o email"
          label="Email"
        />
        <Input name="crefito" placeholder="Informe o crefito" label="Crefito" />

        <Input
          name="color"
          type="color"
          defaultValue="#000"
          placeholder="Informe a cor para a agenda"
          label="Cor para a agenda"
        />

        <hr />

        <ReactSelect
          name="specialty_id"
          label="Especialidade"
          placeholder="Infome a especialidade"
          options={specialties}
          isClearable
        />

        <SignatureInput name="signature_id" label="Assinatura Eletrônica" />

        <button type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
        </button>
        <button
          id="cancel"
          type="button"
          onClick={() => history.push('/professionals')}
        >
          Cancelar
        </button>
      </Form>
    </Container>
  );
}
