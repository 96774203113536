import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  config => {
    const { token, signed } = store.getState().auth;
    if (token) {
      if (!signed) {
        store.dispatch(signOut);
      }
      config.headers.Authorization = `Barear ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.response.status === 401 &&
      error.response.data.code === '401TK'
    ) {
      toast.error('Sua sessão expirou, por favor faça o login novamente!');
      store.dispatch(signOut());
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
