import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { format, parseISO, subDays, getDay, addDays, isBefore } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { MdReceipt, MdSearch, MdLocalPrintshop } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { Container, PatientTable, Historic, SearchDates } from './styles';

import api from '~/services/api';

import ReactDatePicker from '~/components/ReactDatePicker';
import Modal from '~/components/Modal';

const schema = Yup.object().shape({
  hourStart: Yup.string().required('Hora de início é obrigatória'),
  hourEnd: Yup.string().required('Hora de fim é obrigatória'),
});

export default function Patient({ match }) {
  const [historics, setHistorics] = useState([]);
  const [historic, setHistoric] = useState([]);
  const [dateStart, setDateStart] = useState(
    subDays(new Date(), getDay(new Date()))
  );
  const [dateEnd, setDateEnd] = useState(
    addDays(new Date(), 6 - getDay(new Date()))
  );
  const [modalOpen, setModalOpen] = useState(false);

  async function handleHistoric(e) {
    const search = e.target ? e.target.value : '';
    const response = await api.get(
      `appointments/patient/${match.params.patient}`,
      {
        params: {
          search,
          dateStart,
          dateEnd,
        },
      }
    );

    const data = response.data.map(r => ({
      ...r,
      formattedDateStart: format(parseISO(r.start_date), 'dd/MM/yyyy HH:mm'),
      hourStart: parseISO(r.start_date),
      hourEnd: parseISO(r.end_date),
      canceledFormatted: r.canceled_at
        ? format(parseISO(r.canceled_at), 'dd/MM/yyyy HH:mm')
        : '',
    }));

    setHistorics(data);
  }

  function handlePdfGenerate(data) {
    setHistoric(data);
    setModalOpen(true);
  }

  async function generatePdf(data) {
    const d = {
      ...historic,
      hourStart: format(new Date(data.hourStart), 'HH:mm'),
      hourEnd: format(new Date(data.hourEnd), 'HH:mm'),
    };

    if (isBefore(new Date(data.hourEnd), new Date(data.hourStart))) {
      toast.warn('O horário de início não pode ser maior que o final!');
    } else {
      const response = await api.post(
        'report',
        {
          appointment: d,
        },
        {
          responseType: 'arraybuffer',
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setModalOpen(false);
      window.open(fileURL);
    }
  }

  useEffect(() => {
    async function searchHistoric() {
      const { patient } = match.params;
      const response = await api.get(`patients/${patient}/historic`, {
        params: {
          dateStart,
          dateEnd,
        },
      });
      const data = response.data.map(r => ({
        ...r,
        formattedDateStart: format(parseISO(r.start_date), 'dd/MM/yyyy HH:mm'),
        hourStart: parseISO(r.start_date),
        hourEnd: parseISO(r.end_date),
        canceledFormatted: r.canceled_at
          ? format(parseISO(r.canceled_at), 'dd/MM/yyyy HH:mm')
          : '',
      }));
      console.tron.log(data);
      setHistorics(data);
    }
    searchHistoric();
  }, [dateEnd, dateStart, match.params]);

  return (
    <Container>
      {modalOpen && (
        <Modal size="big" close={() => setModalOpen(false)}>
          <Form schema={schema} initialData={historic} onSubmit={generatePdf}>
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              label="Início"
              id="hourStart"
              name="hourStart"
              locale={pt}
              timeCaption="Hora"
              dateFormat="HH:mm"
              mask="99:99"
            />
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              label="Fim"
              id="hourEnd"
              name="hourEnd"
              locale={pt}
              timeCaption="Hora"
              dateFormat="HH:mm"
              mask="99:99"
            />
            <hr />
            <button type="submit" id="default">
              <MdLocalPrintshop size={24} color="#fff" />
              <p>Gerar</p>
            </button>
          </Form>
        </Modal>
      )}
      <SearchDates>
        <div>
          <ReactDatePicker
            label="Início"
            id="date_start"
            name="date_start"
            selected={dateStart}
            onChange={date => setDateStart(date)}
            locale={pt}
            dateFormat="dd/MM/yyyy"
            mask="99/99/9999"
          />
        </div>
        <div>
          <ReactDatePicker
            label="Fim"
            id="date_end"
            name="date_end"
            selected={dateEnd}
            onChange={date => setDateEnd(date)}
            locale={pt}
            dateFormat="dd/MM/yyyy"
            mask="99/99/9999"
          />
        </div>
        <div id="button">
          <button type="button" onClick={handleHistoric}>
            <MdSearch />
          </button>
        </div>
      </SearchDates>
      <input
        placeholder="Digite o nome do profissional ou especialidade..."
        onChange={handleHistoric}
      />
      <hr />

      <PatientTable>
        <thead>
          <tr>
            <th>Profissional</th>
            <th>Data</th>
            <th>Especialidade</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {historics.map(h => (
            <Historic key={h.id} cancelled={!!h.canceled_at}>
              <td>
                <strong>{h.professional.name}</strong>
              </td>
              <td>
                <strong>
                  {h.formattedDateStart}{' '}
                  {h.canceled_at && (
                    <span>cancelado em {h.canceledFormatted}</span>
                  )}
                </strong>
              </td>
              <td>
                <strong>{h.professional.specialty.description}</strong>
              </td>
              <td>
                {!h.canceled_at && h.professional.specialty.id !== 2 && h.past && (
                  <button onClick={() => handlePdfGenerate(h)} type="button">
                    <MdReceipt
                      size={22}
                      title="Gerar declaração de comparecimento"
                    />
                  </button>
                )}
              </td>
            </Historic>
          ))}
        </tbody>
      </PatientTable>
    </Container>
  );
}

Patient.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patient: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
