import { takeLatest, call, put, all } from 'redux-saga/effects';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { toast } from 'react-toastify';
import {
  loadNotificationsSuccess,
  loadNotificationsFailure,
  editNotificationSuccess,
  editNotificationFailure,
} from './actions';

import api from '~/services/api';

export function* loadNotifications() {
  try {
    const response = yield call(api.get, 'notifications');

    const data = response.data.map(notification => ({
      ...notification,
      timeDistance: formatDistance(
        parseISO(notification.createdAt),
        new Date(),
        { addSuffix: true, locale: pt }
      ),
    }));

    yield put(loadNotificationsSuccess(data));
  } catch (error) {
    toast.error(`Erro ao listar apontamentos!`);
    yield put(loadNotificationsFailure());
  }
}

export function* editNotification({ payload }) {
  try {
    yield call(api.put, `notifications/${payload.data._id}`);
    yield put(editNotificationSuccess(payload.data));
  } catch (error) {
    yield put(editNotificationFailure());
  }
}

export default all([
  takeLatest('@notification/LOAD_NOTIFICATIONS_REQUEST', loadNotifications),
  takeLatest('@notification/EDIT_NOTIFICATION_REQUEST', editNotification),
]);
