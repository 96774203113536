import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import pt from 'date-fns/locale/pt-BR';
import { parseISO } from 'date-fns';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactDatePicker from '~/components/ReactDatePicker';

import api from '~/services/api';
import history from '~/services/history';

import { editPatientRequest } from '~/store/modules/patient/actions';

import { Container } from '../styles';
import ReactSelect from '~/components/ReactSelect';
import Input from '~/components/Input';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cpf: Yup.string().required('O CPF é obrigatório'),
  card: Yup.string(),
  email: Yup.string().email('Informe um email válido'),
  phone: Yup.string(),
  cellphone: Yup.string(),
  birth: Yup.string().required('É necessário informar a data de nascimento'),
  agreement_id: Yup.string().required('O convênio é obrigatório'),
  cep: Yup.string().required('É necessário informar o CEP'),
  address: Yup.string().required('É necessário informar o logradouro'),
  number: Yup.string().required('É necessário informar o número'),
  district: Yup.string().required('É necessário informar o bairro'),
  uf: Yup.string().required('É necessário informar o estado'),
  city: Yup.string().required('É necessário informar a cidade'),
  complement: Yup.string(),
});

export default function Patient({ match }) {
  const [load, setLoad] = useState(true);
  const loading = useSelector(state => state.patient.loading);
  const [agreements, setAgreements] = useState({});
  const [patient, setPatient] = useState([]);
  const [addressPatient, setAddressPatient] = useState({});
  const dispatch = useDispatch();

  function handleSubmit(data) {
    const dataPatient = {
      id: patient.id,
      agreement_id: data.agreement_id,
      birth: data.birth,
      cellphone: data.cellphone.replace(/[^0-9]/g, ''),
      phone: data.phone.replace(/[^0-9]/g, ''),
      email: data.email,
      card: data.card.replace(/[^0-9]/g, ''),
      cpf: data.cpf.replace(/[^0-9]/g, ''),
      name: data.name,
      address: {
        id: patient.idAddress,
        cep: data.cep,
        address: data.address,
        number: data.number,
        district: data.district,
        uf: data.uf,
        city: data.city,
        complement: data.complement,
        ibge: patient.ibge || addressPatient.ibge,
      },
    };
    dispatch(editPatientRequest(dataPatient));
  }

  async function handleChangeCep(e) {
    const cep = e.target.value;

    if (cep.length === 8) {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
      setAddressPatient({
        address: response.data.logradouro,
        district: response.data.bairro,
        city: response.data.localidade,
        uf: response.data.uf,
        ibge: response.data.ibge,
      });
    }
  }

  useEffect(() => {
    async function loadAgreements() {
      const response = await api.get('agreements');
      const data = response.data.map(agreement => ({
        id: agreement.id,
        title: agreement.description,
      }));
      setAgreements(data);
    }
    async function loadPatient() {
      const response = await api.get('patients', {
        params: {
          id: match.params.patient,
        },
      });

      const data = {
        id: response.data.id,
        age: response.data.age,
        name: response.data.name,
        cpf: response.data.cpf,
        card: response.data.card,
        email: response.data.email,
        phone: response.data.phone,
        cellphone: response.data.cellphone,
        birth: parseISO(response.data.birth),
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt,
        agreement_id: response.data.agreement_id,
        agreement: {
          id: response.data.agreement.id,
          description: response.data.agreement.description,
          createdAt: response.data.agreement.createdAt,
          updatedAt: response.data.agreement.updatedAt,
        },
        idAddress: response.data.address ? response.data.address.id : '',
        address: response.data.address ? response.data.address.address : '',
        district: response.data.address ? response.data.address.district : '',
        cep: response.data.address ? response.data.address.cep : '',
        number: response.data.address ? response.data.address.number : '',
        city: response.data.address ? response.data.address.city : '',
        complement: response.data.address
          ? response.data.address.complement
          : '',
        uf: response.data.address ? response.data.address.uf : '',
        ibge: response.data.address ? response.data.address.ibge : '',
      };

      setPatient(data);
    }
    async function loadFunctions() {
      setLoad(true);
      await loadAgreements();
      await loadPatient();
      setLoad(false);
    }

    loadFunctions();
  }, []); // eslint-disable-line

  return (
    <Container loading={loading} load={load}>
      {load ? (
        <div id="load">
          <FaSpinner size={24} color="#f47920" background="none" />
        </div>
      ) : (
        <Form initialData={patient} schema={schema} onSubmit={handleSubmit}>
          <Input
            name="name"
            placeholder="Informe o nome do paciente"
            label="Nome"
          />

          <Input
            name="cpf"
            placeholder="Informe o Cpf do paciente"
            label="CPF"
            mask="999.999.999-99"
          />

          <Input
            name="card"
            placeholder="Informe a carteirinha do paciente"
            label="Carteirinha (0000 0000000000000)"
            mask="9999 9999999999999"
          />

          <ReactDatePicker
            label="Data de nascimento"
            id="birth"
            name="birth"
            mask="99/99/9999"
            locale={pt}
          />

          <hr />

          <Input
            name="email"
            type="email"
            placeholder="Informe o email do paciente"
            label="Email"
          />

          <Input
            name="phone"
            placeholder="Informe o número de telefone"
            label="Telefone"
            mask="(99) 9999-9999"
          />

          <Input
            name="cellphone"
            placeholder="Informe o número de celular"
            label="Celular"
            mask="(99) 99999-9999"
          />

          <Input
            name="cep"
            placeholder="Informe o cep"
            label="Cep"
            onChange={handleChangeCep}
          />

          <Input
            name="address"
            placeholder="Informe o logradouro"
            label="Logradouro"
            value={addressPatient.address}
          />

          <Input name="number" placeholder="Informe o número" label="Número" />

          <Input
            name="district"
            placeholder="Informe o bairro"
            label="Bairro"
            value={addressPatient.district}
          />

          <Input
            name="complement"
            placeholder="Informe o complemento"
            label="Complemento"
          />

          <Input
            name="city"
            placeholder="Informe a cidade"
            label="Cidade"
            value={addressPatient.city}
          />

          <Input
            name="uf"
            placeholder="Informe o estado"
            label="Estado"
            value={addressPatient.uf}
          />

          <hr />

          <ReactSelect
            name="agreement_id"
            label="Convênio"
            placeholder="Informe o convênio"
            options={agreements}
            isClearable
          />

          <button type="submit">
            {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
          </button>

          <button
            id="cancel"
            type="button"
            onClick={() => history.push('/patients')}
          >
            Cancelar
          </button>
        </Form>
      )}
    </Container>
  );
}
Patient.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patient: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
