import React from 'react';
import PropTypes from 'prop-types';
import { FiXSquare } from 'react-icons/fi';

import { Container, Content, Scroll } from './styles';

const Modal = ({ children, size, close }) => (
  <Container>
    <Scroll>
      <Content size={size}>
        <FiXSquare size="22px" onClick={close} />
        {children}
      </Content>
    </Scroll>
  </Container>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  size: PropTypes.string,
  close: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  size: 'default',
};

export default Modal;
