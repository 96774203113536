import React from 'react';
import * as Yup from 'yup';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import ReactSelect from '~/components/ReactSelect';

import { createAgreementRequest } from '~/store/modules/agreement/actions';

import history from '~/services/history';

import { Container } from './styles';
import tableValues from '~/utils/tableValues';

const schema = Yup.object().shape({
  description: Yup.string().required('A descrição é obrigatória'),
  table_name: Yup.string().required('Informe a tabela de valor'),
});

export default function Agreement() {
  const loading = useSelector(state => state.agreement.loading);
  const dispatch = useDispatch();

  function handleSubmit(data) {
    dispatch(createAgreementRequest(data));
  }

  return (
    <Container loading={loading}>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          name="description"
          placeholder="Informe o convênio"
          label="Descrição*"
        />

        <ReactSelect
          name="table_name"
          label="Tabelas de valores"
          placeholder="Informe o tabela de valor"
          options={tableValues}
        />

        <button type="submit">
          {loading ? <FaSpinner size={24} color="#fff" /> : 'Salvar'}
        </button>
        <button
          id="cancel"
          type="button"
          onClick={() => history.push('/agreements')}
        >
          Cancelar
        </button>
      </Form>
    </Container>
  );
}
