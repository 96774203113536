import React, { useEffect, useState, useMemo, useCallback } from 'react';
import socketio from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import pt from 'date-fns/locale/pt-BR';
import {
  addHours,
  addDays,
  getDay,
  subDays,
  setHours,
  setMinutes,
  setSeconds,
  format,
  parseISO,
  addMinutes,
} from 'date-fns';
import moment from 'moment';
import locale from 'moment/locale/pt-br'; //eslint-disable-line
import { toast } from 'react-toastify';
import { Form, Input, Textarea } from '@rocketseat/unform';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';

import { FaSpinner, FaSave, FaCalendarCheck } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import api from '~/services/api';

import SideBar from '~/components/SideBar';

import {
  loadAppointmentsRequest,
  createAppointmentRequest,
  editAppointmentRequest,
  deleteAppointmentRequest,
} from '~/store/modules/appointment/actions';

import { loadWaitRequest } from '~/store/modules/wait/actions';

import Modal from '../Modal';
import ReactDatePicker from '../ReactDatePicker';
import ReactSelect from '../ReactSelect';
import ReactSelectAsync from '../ReactSelectAsync';
import {
  Content,
  Container,
  Title,
  Professionals,
  Professional,
  Filters,
  Cal,
  Dates,
} from './styles';

const schema = Yup.object().shape({
  professional_id: Yup.number()
    .required('O profissional é obrigatório')
    .typeError('O profissional é obrigatório'),
  patient_id: Yup.number().required('O paciente é obrigatório'),
  doctor_name: Yup.string(),
  doctor_crm: Yup.string(),
  date_start: Yup.date().required('A data inicial é obrigatória'),
  date_end: Yup.date().required('A data final é obrigatória'),
  description: Yup.string(),
  agreement_id: Yup.number()
    .required('Informe o plano de saúde')
    .typeError('Informe o plano de saúde'),
  service_code: Yup.string(),
  service_value: Yup.number()
    // eslint-disable-next-line no-restricted-globals
    .transform(value => (isNaN(value) ? 0 : value))
    .when('service_code', {
      is: val => !val,
      then: Yup.number(),
      otherwise: Yup.number()
        .min(1, 'Informe o valor')
        .max(999)
        .required('Informe o valor'),
    }),
  payment_type: Yup.string()
    .oneOf(['Cartão', 'À vista'], 'Informe a forma de pagamento')
    .required('Informe a forma de pagamento'),
});

export default function Schedule() {
  const { profile } = useSelector(state => state.user);
  const [load, setLoad] = useState(false);
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const { appointments, loading } = useSelector(state => state.appointment);
  const [currentDate, setCurrentDate] = useState(new Date());
  const wait = useSelector(state => state.waits);
  const [currentView, setCurrentView] = useState('week');
  const [appointmentEditing, setAppointmentEditing] = useState(undefined);
  const [appointmentAdded, setAppointmentAdded] = useState(undefined);
  const [doctor, setDoctor] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [service, setService] = useState({});
  const [agreements, setAgreements] = useState([]);
  const [tableName, setTableName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [patients, setPatients] = useState(undefined);
  const [professionals, setProfessionals] = useState(undefined);
  const [professionalSelected, setProfessionalSelected] = useState({});
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(addHours(new Date(), 1));
  const minTime = setSeconds(setMinutes(setHours(new Date(), 6), 0), 0);
  const maxTime = setSeconds(setMinutes(setHours(new Date(), 19), 0), 0);
  moment.locale('pt-BR');
  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const payment_type = [
    {
      id: 'Cartão',
      title: 'Cartão',
    },
    {
      id: 'À vista',
      title: 'À vista',
    },
  ];

  function navigate(dateSelected) {
    const professional_id = professionalSelected.id;
    if (currentView === 'day') {
      dispatch(
        loadAppointmentsRequest({
          start_date: dateSelected,
          end_date: dateSelected,
          professional_id,
        })
      );
    } else {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(dateSelected, getDay(dateSelected)),
          end_date: addDays(dateSelected, 6 - getDay(dateSelected)),
          professional_id,
        })
      );
    }
    setCurrentDate(dateSelected);
  }

  function changeProfessionalSelected(professional) {
    if (professionalSelected && professional.id !== professionalSelected.id) {
      setProfessionalSelected(professional);
    } else {
      setProfessionalSelected({});
    }
  }

  function newEvent(data) {
    setDoctor([]);
    setAppointmentEditing(undefined);
    setAppointmentAdded(data);
    setDateStart(data.start);
    setDateEnd(data.end);
    setModalOpen(true);
  }

  function confirm(e) {
    if (!e.confirmed) {
      confirmAlert({
        title: 'Confirmar agendamento',
        message: `Deseja confirmar o agendamento de ${e.title} das ${format(
          e.start,
          'HH:mm'
        )} às ${format(e.end, 'HH:mm')}?`,
        buttons: [
          {
            label: 'Sim',
            onClick: () =>
              dispatch(
                editAppointmentRequest({
                  id: e.id,
                  professional_id: e.professional_id,
                  patient_id: e.patient_id,
                  start_date: e.start,
                  end_date: e.end,
                  confirmed: true,
                  payment_type: e.payment_type,
                  agreement_id: e.agreement_id,
                })
              ),
          },
          {
            label: 'Não',
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      });
    }
  }

  const searchService = useCallback(
    async (value, callback) => {
      const response = await api.get('appointments/services', {
        params: {
          search: value,
          table_name: tableName,
        },
      });

      const data = response.data.map(s => ({
        value: s.code,
        label: s.description,
        price: s.value,
      }));

      callback(data);
    },
    [tableName]
  );

  function doubleClickEvent(appointment, wait = false) {
    if (wait === true) {
      setDateStart(new Date());
      setDateEnd(addMinutes(new Date(), 30));
    } else {
      setDateStart(appointment.start);
      setDateEnd(appointment.end);
      setDoctor({ name: appointment.doctor_name, crm: appointment.doctor_crm });
      setPatients([
        {
          value: appointment.patient.id,
          label: appointment.patient.name,
        },
      ]);
      setService({
        value: appointment.service_code,
        label: appointment.service_name,
      });
    }
    setAppointmentEditing(appointment);
    setModalOpen(true);
  }

  function dragAndDropEvent(event) {
    const appointment = event.event;
    const dt = {
      ...appointment,
      start_date: event.start,
      end_date: event.end,
    };
    dispatch(editAppointmentRequest(dt));
  }

  function handleToggleModal() {
    setModalOpen(!modalOpen);
  }
  function handleCancelAppointment() {
    dispatch(deleteAppointmentRequest(appointmentEditing));
    handleToggleModal();
  }

  async function searchDoctors(e) {
    if (e.target.value !== '') {
      setDoctor([]);
      const response = await api.get('cooperate', {
        params: {
          search: e.target.value,
        },
      });

      const data = response.data.map(cooperated => ({
        value: cooperated.CONTRATO,
        label: `${cooperated.CONTRATO} - ${cooperated.NOME}`,
        name: cooperated.NOME,
        crm: cooperated.CRM,
      }));
      setIsVisible(true);
      setDoctors(data);
      return data;
    }
    setDoctors(undefined);
    return null;
  }

  function handleClickDoctor(dt) {
    setDoctor(dt);
    setIsVisible(false);
  }

  function handleSubmit(data) {
    const { label: service_name } = service;
    if (appointmentEditing) {
      const dt = {
        id: appointmentEditing.id,
        ...data,
        doctor,
        start_date: dateStart,
        end_date: dateEnd,
        wait: false,
        service_name,
        service_code: data.service_code || '',
      };
      dispatch(editAppointmentRequest(dt));
      handleToggleModal();
    } else {
      const dt = {
        ...data,
        // eslint-disable-next-line no-restricted-globals
        doctor_crm: isNaN(parseInt(data.doctor_crm, 10))
          ? 0
          : parseInt(data.doctor_crm, 10),
        doctor,
        start_date: dateStart,
        end_date: dateEnd,
        service_name,
      };
      dispatch(createAppointmentRequest(dt));
      handleToggleModal();
    }
  }

  function viewChanged(view) {
    setCurrentDate(new Date());
    const professional_id = professionalSelected.id;
    if (view === 'day') {
      dispatch(
        loadAppointmentsRequest({
          start_date: new Date(),
          end_date: new Date(),
          professional_id,
        })
      );
    } else {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(new Date(), getDay(new Date())),
          end_date: addDays(new Date(), 6 - getDay(new Date())),
          professional_id,
        })
      );
    }
    setCurrentView(view);
  }

  const changeService = async (inputValue, { action }) => {
    if (action === 'clear') {
      setService({ price: '' });
      return;
    }
    setService(inputValue);
  };

  const changeValue = async e => {
    const newState = { ...service, price: parseFloat(e.target.value) };
    setService(newState);
  };

  const socket = useMemo(
    () =>
      profile &&
      socketio(process.env.REACT_APP_API_URL, {
        query: { user_id: profile.id },
      }),
    [profile]
  );

  useEffect(() => {
    const professional_id = professionalSelected.id;
    function appointmentCreated(data) {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(currentDate, getDay(currentDate)),
          end_date: addDays(currentDate, 6 - getDay(currentDate)),
          professional_id,
        })
      );

      const formattedDate = format(
        parseISO(data.start_date),
        "'dia' dd 'de' MMMM', às' H:mm'h'",
        {
          locale: pt,
        }
      );

      toast.info(
        `Novo agendamento de ${data.patient.name} para o ${formattedDate}, para o prestador ${data.professional.name}`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
    function appointmentUpdated(data) {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(currentDate, getDay(currentDate)),
          end_date: addDays(currentDate, 6 - getDay(currentDate)),
          professional_id,
        })
      );
      dispatch(loadWaitRequest());
    }
    function appointmentDeleted(data) {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(currentDate, getDay(currentDate)),
          end_date: addDays(currentDate, 6 - getDay(currentDate)),
          professional_id,
        })
      );

      const formattedDate = format(
        parseISO(data.start_date),
        "'dia' dd 'de' MMMM', às' H:mm'h'",
        {
          locale: pt,
        }
      );

      toast.warn(
        `O agendamento de ${data.patient.name} para o ${formattedDate}, para o prestador ${data.professional.name} foi cancelado e se encontra novamente disponível`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
    function waitLoaded(data) {
      dispatch(loadWaitRequest());
    }
    if (socket) {
      socket.on('appointment_created', data => {
        appointmentCreated(data);
      });

      socket.on('appointment_updated', data => {
        appointmentUpdated(data);
      });

      socket.on('appointment_deleted', data => {
        appointmentDeleted(data);
      });

      socket.on('load_wait', data => {
        if (profile) waitLoaded(data);
      });
    }
  }, [currentDate, dispatch, professionalSelected.id, profile, socket]);

  const searchPatient = useCallback(async (value, callback) => {
    try {
      const response = await api.get('patients', {
        params: {
          search: value,
        },
      });
      const data = response.data.map(patient => ({
        value: patient.id,
        label: `${patient.name} - ${patient.cpf}`,
      }));
      callback(data);
    } catch (error) {
      console.tron.log(error);
    }
  }, []);

  const changeAgreement = e => {
    setTableName(e.table_name);
    setService({ price: '' });
  };

  useEffect(() => {
    async function loadPatients() {
      try {
        const response = await api.get('patients');
        const data = response.data.map(patient => ({
          value: patient.id,
          label: `${patient.name} - ${patient.cpf}`,
        }));
        setPatients(data);
      } catch (error) {
        console.tron.log(error);
      }
    }
    async function loadProfessionals() {
      try {
        const response = await api.get('professionals');
        const data = response.data.professionals.map(p => ({
          id: p.id,
          title: p.name,
          color: p.color,
        }));

        setProfessionals(data);
      } catch (error) {
        console.tron.log(error);
      }
    }
    async function loadAppointments() {
      dispatch(
        loadAppointmentsRequest({
          start_date: subDays(currentDate, getDay(currentDate)),
          end_date: addDays(currentDate, 6 - getDay(currentDate)),
          professional_id: professionalSelected.id,
        })
      );
    }
    async function loadAgreements() {
      const response = await api.get('agreements');
      let data = [];
      if (response) {
        data = response.data.map(agreement => ({
          id: agreement.id,
          title: agreement.description,
          table_name: agreement.table_name,
        }));
      }
      setAgreements(data);
    }
    async function loadWait() {
      dispatch(loadWaitRequest());
    }
    async function loadFunctions() {
      setLoad(true);
      await Promise.all([
        loadProfessionals(),
        loadAppointments(),
        loadPatients(),
        loadWait(),
        loadAgreements(),
      ]);
      setLoad(false);
    }
    loadFunctions();
  }, [professionalSelected.id]); //eslint-disable-line

  const eventProp = data => {
    let style = null;
    if (data.confirmed) {
      style = {
        backgroundColor: `${data.professional.color}`,
        border: `${data.professional.color}`,
      };
      if (data.past) {
        style.opacity = 0.6;
      }
    } else {
      style = {
        backgroundColor: '#999',
        border: '#999',
      };
    }

    return {
      className: '',
      style,
    };
  };

  return (
    <>
      {modalOpen && (
        <Modal size="big" close={() => handleToggleModal()}>
          {!appointmentEditing && (
            <header>
              <div id="title">
                <h1>Novo Agendamento</h1>
              </div>
            </header>
          )}
          <div>
            <Form
              schema={schema}
              initialData={appointmentEditing || appointmentAdded}
              onSubmit={handleSubmit}
            >
              <ReactSelect
                name="professional_id"
                label="Profissional"
                placeholder="Informe o profissional"
                options={professionals}
                isClearable
              />
              <ReactSelectAsync
                name="patient_id"
                label="Paciente"
                cacheOptions
                placeholder="Informe o paciente"
                loadOptions={searchPatient}
                isClearable
                defaultOptions={patients}
              />

              <ReactSelect
                name="agreement_id"
                label="Convênio"
                placeholder="Informe o convênio"
                options={agreements}
                onChange={changeAgreement}
              />

              <ReactSelectAsync
                name="service_code"
                label="Procedimento"
                placeholder="Informe o procedimento"
                loadOptions={searchService}
                isClearable
                onChange={changeService}
                defaultOptions={[service]}
              />

              <Input
                name="service_value"
                placeholder="Valor"
                label="Valor do procedimento"
                autoComplete="off"
                type="number"
                step="0.01"
                value={service.price}
                onChange={changeValue}
              />

              <ReactSelect
                name="payment_type"
                label="Forma de pagamento"
                placeholder="Informe a forma de pagamento"
                options={payment_type}
              />

              <Input
                name="doctor_name"
                placeholder="Informe o nome"
                label="Nome do Solicitante"
                onChange={searchDoctors}
                value={doctor.name}
                autoComplete="off"
              />
              {doctors && isVisible && (
                <Filters>
                  {doctors.map(dt => (
                    <ul key={dt.value} onClick={() => handleClickDoctor(dt)}>
                      {dt.label}
                    </ul>
                  ))}
                </Filters>
              )}

              <Input
                name="doctor_crm"
                placeholder="Informe o número do conselho"
                label="CRM"
                value={doctor.crm}
              />
              <Dates>
                <div>
                  <ReactDatePicker
                    label="Início"
                    id="date_start"
                    name="date_start"
                    showTimeSelect
                    timeCaption="Hora"
                    selected={dateStart}
                    onChange={date => setDateStart(date)}
                    locale={pt}
                    dateFormat="dd/MM/yyyy hh:mm:aa"
                  />
                </div>
                <div>
                  <ReactDatePicker
                    label="Fim"
                    id="date_end"
                    name="date_end"
                    showTimeSelect
                    timeCaption="Hora"
                    selected={dateEnd}
                    onChange={date => setDateEnd(date)}
                    locale={pt}
                    dateFormat="dd/MM/yyyy hh:mm:aa"
                  />
                </div>
              </Dates>

              <Textarea
                name="description"
                label="Descrição"
                placeholder="Informe uma descrição"
              />

              <hr />
              <div id="buttons">
                {appointmentEditing ? (
                  <button type="submit" id="default">
                    {loading ? (
                      <FaSpinner size={24} color="#fff" />
                    ) : (
                      <FaSave size={24} color="#fff" />
                    )}
                    <p>Alterar</p>
                  </button>
                ) : (
                  <button type="submit" id="default">
                    {loading ? (
                      <FaSpinner size={24} color="#fff" />
                    ) : (
                      <FaCalendarCheck size={24} color="#fff" />
                    )}
                    <p>Salvar</p>
                  </button>
                )}
                {appointmentEditing && !appointmentEditing.past ? (
                  <button
                    id="delete"
                    type="button"
                    title="Cancelar Agendamento"
                    onClick={handleCancelAppointment}
                  >
                    <MdDeleteForever size={24} color="#fff" />
                    Cancelar Agendamento
                  </button>
                ) : (
                  ''
                )}
              </div>
            </Form>
          </div>
        </Modal>
      )}
      <Container load={load}>
        <Content>
          <SideBar
            active
            doubleClickEvent={doubleClickEvent}
            patients={patients && patients}
            professionals={professionals && professionals}
            wait={wait}
            setAppointmentEditing={setAppointmentEditing}
          />
          <Cal>
            <Professionals>
              {professionals
                ? professionals.map(professional => (
                    <Professional
                      color={professional.color}
                      key={professional.id}
                      selected={
                        professionalSelected &&
                        professionalSelected.id === professional.id
                      }
                      onClick={() => changeProfessionalSelected(professional)}
                    >
                      <div />
                      {professional.title}
                    </Professional>
                  ))
                : ''}
            </Professionals>
            <Title>
              <h1>Calendário</h1>
              <ReactDatePicker
                name="dt"
                selected={currentDate}
                onChange={date => setCurrentDate(date)}
                locale={pt}
                dateFormat="dd/MM/yyyy"
              />
            </Title>
            <DragAndDropCalendar
              selectable
              resizable
              culture="pt-BR"
              localizer={localizer}
              min={minTime}
              max={maxTime}
              defaultDate={currentDate}
              defaultView={currentView}
              events={appointments}
              startAccessor="start"
              endAccessor="end"
              views={['day', 'week']}
              onView={viewChanged}
              showMultiDayTimes
              onNavigate={navigate}
              onDoubleClickEvent={doubleClickEvent}
              onSelectSlot={newEvent}
              onEventDrop={dragAndDropEvent}
              onEventResize={dragAndDropEvent}
              eventPropGetter={eventProp}
              components={{
                eventWrapper: ({ event, children }) => (
                  <div
                    onContextMenu={e => {
                      confirm(event);
                      e.preventDefault();
                    }}
                  >
                    {children}
                  </div>
                ),
              }}
              popup
            />
          </Cal>
        </Content>
      </Container>
    </>
  );
}
