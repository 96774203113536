import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Profile from '../pages/Profile';
import PatientList from '../pages/Patient/PatientList';
import Patient from '../pages/Patient';
import PatientEdit from '../pages/Patient/PatientEdit';
import HistoricPatient from '../pages/Patient/HistoricPatient';
import Dashboard from '../pages/Dashboard';
import Professional from '../pages/Professional';
import ProfessionalList from '../pages/Professional/ProfessionalList';
import ProfessionalEdit from '../pages/Professional/ProfessionalEdit';

import AgreementList from '~/pages/Agreement/AgreementList';
import Agreement from '~/pages/Agreement';
import AgreementEdit from '~/pages/Agreement/AgreementEdit';

import Chart from '~/pages/Chart';
import Report from '~/pages/Report';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/patient" exact component={Patient} isPrivate />
      <Route path="/patients" component={PatientList} isPrivate />
      <Route path="/patient/:patient/edit" component={PatientEdit} isPrivate />
      <Route
        path="/patient/:patient/historic"
        component={HistoricPatient}
        isPrivate
      />

      <Route path="/professional" exact component={Professional} isPrivate />
      <Route path="/professionals" component={ProfessionalList} isPrivate />
      <Route
        path="/professional/:professional/edit"
        component={ProfessionalEdit}
        isPrivate
      />

      <Route path="/agreements" exact component={AgreementList} isPrivate />
      <Route path="/agreement" exact component={Agreement} isPrivate />
      <Route
        path="/agreement/:agreement/edit"
        exact
        component={AgreementEdit}
        isPrivate
      />

      <Route path="/charts" exact component={Chart} isPrivate />

      <Route path="/reports" exact component={Report} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
