import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Scroll } from './styles';

const Modal = ({ children, size, title }) => (
  <Container>
    <Scroll>
      <Content title={title} size={size}>
        <h1>{title}</h1>
        {children}
      </Content>
    </Scroll>
  </Container>
);

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  size: 'default',
};

export default Modal;
