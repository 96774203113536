import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';
import search from '~/assets/images/search.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  max-width: 900px;
  margin: 100px auto;
  div#load {
    ${props =>
      props.load &&
      css`
        svg {
          animation: ${rotate} 2s linear infinite;
        }
      `}
  }
  > div#btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;

    > button {
      font-weight: bold;
      border: 0;
      background: #f47920;
      padding: 5px;
      margin-left: 10px;
      border-radius: 5px;
      &:hover {
        background: ${darken(0.03, '#f47920')};
      }
      > a {
        display: flex;
        align-items: center;
        color: #fff;
        > svg {
          margin-right: 10px;
        }
      }
    }
  }

  > input:focus {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }

  input {
    width: 100%;
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 50px;
    color: #333;
    margin: 0 0 10px;
    background: rgba(0, 0, 0, 0.1) url(${search}) no-repeat 860px center;
    &::placeholder {
      color: #333;
    }
  }

  hr {
    border: 1px solid #999;
    margin: 10px 0 5px;
  }
`;

export const AgreementTable = styled.table`
  width: 100%;
  thead th {
    color: #999;
    text-align: left;
    padding: 12px;
  }

  tbody td {
    padding: 7px;
    border-bottom: 2px solid #eee;
    button {
      border-radius: 3px;
      background: #f47920;
      color: #fff;

      &:hover {
        background: ${darken(0.08, '#f47920')};
      }
    }

    > a strong {
      text-decoration: underline;

      &:hover {
        color: #f47920;
      }
    }
  }

  strong {
    color: #333;
    display: block;
  }

  span {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: center;

    input {
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
      padding: 6px;
      width: 50px;
    }
  }

  button {
    background: none;
    border: 0;
    padding: 6px;
  }
`;

export const Color = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${props => props.color};
`;
