import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { format, parseISO } from 'date-fns';
import { MdEvent } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { Container, PatientTable } from './styles';

import api from '~/services/api';

export default function Patient() {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  async function searchPatient(e) {
    try {
      setLoading(true);
      const response = await api.get('patients', {
        params: {
          search: e.target.value,
        },
      });

      const data = response.data.map(patient => ({
        ...patient,
        birthFormattedDate: format(parseISO(patient.birth), 'dd/MM/yyyy'),
        birthFormatted: {
          value: '**/**/****',
          visible: false,
        },
        cellPhoneFormatted: {
          value: `${patient.cellphone.substr(
            0,
            2
          )}*****${patient.cellphone.substr(9, 2)}`,
          visible: false,
        },
        cardFormatted: {
          value: `${patient.card.substr(0, 4)}***********${patient.card.substr(
            15,
            2
          )}`,
          visible: false,
        },
        ageFormatted: {
          value: '**',
          visible: false,
        },
      }));

      setPatients(data);
      setLoading(false);
      setTotal(
        Number(response.headers['x-total-count'])
          ? Number(response.headers['x-total-count'])
          : response.data.length
      );
      if (Number(response.headers['x-total-count'])) {
        setPage(2);
      }
    } catch (error) {
      console.tron.log(error);
    }
  }
  const loadPatients = useCallback(async () => {
    try {
      if (loading) {
        return;
      }

      if (total > 0 && patients.length === total) {
        return;
      }
      setLoading(true);
      const response = await api.get('patients', {
        params: { page },
      });

      const data = response.data.map(patient => ({
        ...patient,
        birthFormattedDate: format(parseISO(patient.birth), 'dd/MM/yyyy'),
        birthFormatted: {
          value: '**/**/****',
          visible: false,
        },
        cellPhoneFormatted: {
          value: `${patient.cellphone.substr(
            0,
            2
          )}*****${patient.cellphone.substr(9, 2)}`,
          visible: false,
        },
        cardFormatted: {
          value: `${patient.card.substr(0, 4)}***********${patient.card.substr(
            15,
            2
          )}`,
          visible: false,
        },
        ageFormatted: {
          value: '**',
          visible: false,
        },
      }));
      if (patients.length > 0) {
        setPatients([...patients, ...data]);
      } else {
        setPatients(data);
      }
      setTotal(Number(response.headers['x-total-count']));
      setPage(page + 1);
      setLoading(false);
    } catch (error) {
      console.tron.log(error);
    }
  }, [loading, page, patients, total]);

  useEffect(() => {
    loadPatients();
  }, []); //eslint-disable-line

  function handleVisible(patient, field) {
    const data = patients.map(p => ({
      ...p,
      [field]: {
        value: p[field].value,
        visible: p.id === patient.id ? !p[field].visible : p[field].visible,
      },
    }));
    setPatients(data);
  }

  return (
    <Container>
      <input
        placeholder="Digite o nome, carteirinha , telefone ou CPF..."
        onChange={searchPatient}
      />
      <hr />
      <PatientTable>
        <thead>
          <tr>
            <th>NOME</th>
            <th>TELEFONE</th>
            <th>CARTEIRINHA</th>
            <th>DATA DE NASCIMENTO</th>
            <th>IDADE</th>
            <th>CONVÊNIO</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {patients.map(patient => (
            <tr key={patient.id}>
              <td>
                <Link to={`patient/${patient.id}/edit`}>
                  <strong id="name">{patient.name}</strong>
                </Link>
              </td>
              <td>
                <strong
                  onClick={() => handleVisible(patient, 'cellPhoneFormatted')}
                  onKeyPress={() =>
                    handleVisible(patient, 'cellPhoneFormatted')
                  }
                  role="button"
                  tabIndex="0"
                >
                  {patient.cellPhoneFormatted.visible
                    ? patient.cellphone
                    : patient.cellPhoneFormatted.value}
                </strong>
              </td>
              <td>
                <strong
                  onClick={() => handleVisible(patient, 'cardFormatted')}
                  onKeyPress={() => handleVisible(patient, 'cardFormatted')}
                  role="button"
                  tabIndex="0"
                >
                  {patient.cardFormatted.visible
                    ? patient.card
                    : patient.cardFormatted.value}
                </strong>
              </td>
              <td>
                <strong
                  onClick={() => handleVisible(patient, 'birthFormatted')}
                  onKeyPress={() => handleVisible(patient, 'birthFormatted')}
                  role="button"
                  tabIndex="0"
                >
                  {patient.birthFormatted.visible
                    ? patient.birthFormattedDate
                    : patient.birthFormatted.value}
                </strong>
              </td>
              <td>
                <strong
                  onClick={() => handleVisible(patient, 'ageFormatted')}
                  onKeyPress={() => handleVisible(patient, 'ageFormatted')}
                  role="button"
                  tabIndex="0"
                >
                  {patient.ageFormatted.visible
                    ? patient.age
                    : patient.ageFormatted.value}
                </strong>
              </td>
              <td>
                <strong>{patient.agreement.description}</strong>
              </td>
              <td>
                <Link to={`patient/${patient.id}/historic`}>
                  <MdEvent size={22} title="Histórico de agendamentos" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </PatientTable>
      {total > 0 && patients.length === total ? (
        ''
      ) : (
        <button type="button" onClick={loadPatients}>
          {loading ? <FaSpinner size={20} /> : 'Carregar mais...'}
        </button>
      )}
    </Container>
  );
}
