import styled from 'styled-components';
import {darken} from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 15px;
  margin-top: 30px;
  @media (max-width : 1030px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin : 50px 50px 5px;

  >a{
    color: #fff;
    background-color: #f47920;
    padding: 5px;
    border-radius: 4px;
    &:hover{
      background-color : ${darken(0.05,'#f47920')};
    }
  }
`;






