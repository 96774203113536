import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const cont = styled.div``;

export const Container = styled.div`
  z-index: 0;

  ${props =>
    props.load &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Cal = styled.div`
  width: 920px;
  max-width: 920px;
  height: 1200px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 0;
  h1 {
    color: #f47920;
    margin-bottom: 10px;
  }
  .react-datepicker-popper {
    z-index: 5;
  }
  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 34px;
    color: #333;
    text-align: center;

    &::placeholder {
      color: #333;
    }
  }
`;

export const Filters = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: absolute;
    background-color: #fff;
    width: 570px;
    height: 44px;
    border: 1px solid #999;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-left: 15px;
    cursor: pointer;
    :hover {
      background-color: #f47920;
      color: #fff;
    }
  }
`;

export const Professionals = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Professional = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props =>
    props.selected ? 'rgba(0, 0, 0, 0.1)' : '#fff'};
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  > div {
    width: 10px;
    height: 10px;
    background-color: ${props => props.color};
    margin: 0 10px 0 0;
  }
  & + li {
    margin-left: 100px;
  }
`;

export const Dates = styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-right: 15px;
    }
  }
`;
