import produce from 'immer';

const INITIAL_STATE = {
  patient: null,
  loading: 0,
};

export default function patient(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@patient/CREATE_PATIENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@patient/CREATE_PATIENT_SUCCESS': {
        draft.patient = action.payload.patient;
        draft.loading = 0;
        break;
      }
      case '@patient/CREATE_PATIENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@patient/EDIT_PATIENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@patient/EDIT_PATIENT_SUCCESS': {
        draft.patient = action.payload.patient;
        draft.loading = 0;
        break;
      }
      case '@patient/EDIT_PATIENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      default:
    }
  });
}
