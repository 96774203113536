import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { MdSchedule, MdClose, MdAddCircle } from 'react-icons/md';
import { FaCalendarCheck } from 'react-icons/fa';
import { Form } from '@rocketseat/unform';
import { Container, WaitList, Patient, Scroll, Button } from './styles';

import Modal from '~/components/Modal';
import ReactSelect from '~/components/ReactSelect';
import ReactSelectAsync from '../ReactSelectAsync';
import {
  deleteWaitRequest,
  createWaitRequest,
} from '~/store/modules/wait/actions';


import api from '~/services/api';

const schema = Yup.object().shape({
  professional_id: Yup.string().required('O profissional é obrigatório'),
  patient_id: Yup.string().required('O paciente é obrigatório'),
});
export default function SideBar({ active, doubleClickEvent, patients, professionals, setAppointmentEditing }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { waits } = useSelector(state => state.wait);

  async function handleDelete(wait) {
    dispatch(deleteWaitRequest(wait));
  }

  function handleSubmit(data) {
    dispatch(createWaitRequest(data));
    setOpen(false);
  }

  function handleNewEventClick(wait){
    setAppointmentEditing(wait);
    doubleClickEvent(wait, true);
  }

  const searchPatient = useCallback(async (value, callback) => {
    try {
      const response = await api.get('patients', {
        params: {
          search: value,
        },
      });
      const data = response.data.map(patient => ({
        value: patient.id,
        label: `${patient.name} - ${patient.cpf}`,
      }));
      callback(data);
    } catch (error) {
      console.tron.log(error);
    }
  }, []);

  return (
    <Container active={active}>
      {open && (
        <Modal>
          <header>
            <div id="title">
              <h1>Adicionar à lista de espera</h1>
            </div>
          </header>
          <Form schema={schema} onSubmit={handleSubmit}>
            <ReactSelect
              name="professional_id"
              label="Profissional"
              placeholder="Informe o profissional"
              options={professionals}
              isClearable
            />
            <ReactSelectAsync
                name="patient_id"
                label="Paciente"
                placeholder="Informe o paciente"
                loadOptions={searchPatient}
                defaultOptions={patients}
                isClearable
              />
            <hr />
            <button type="submit" id="default">
              {<FaCalendarCheck size={24} color="#fff" />}
              <p>Salvar</p>
            </button>
            <footer>
              <button
                type="button"
                title="Fechar"
                onClick={() => setOpen(false)}
              >
                Fechar
              </button>
            </footer>
          </Form>
        </Modal>
      )}
      <div id="top">
        <strong>Lista de Espera</strong>
        <MdAddCircle
          color="#f47920"
          size={22}
          title="Adicionar"
          onClick={() => setOpen(!open)}
        />
      </div>
      <nav>
        <WaitList>
          <Scroll>
            {waits.map(wait => (
              <Patient key={wait.id}>
                <div id="content">
                  <div id="header">
                    <strong>{wait.patient.name}</strong>
                  </div>
                  <div id="footer">
                    <div>
                      <span>{wait.patient.cellphone}</span>
                      <span id="agreement">
                        {wait.patient.agreement.description}
                      </span>
                    </div>
                    <div id="buttons">
                      <Button
                        type="button"
                        title="Agendar"
                        color="#f47920"
                        onClick={() => handleNewEventClick(wait)}
                      >
                        <MdSchedule color="#fff" size={12} />
                      </Button>
                      <Button
                        type="button"
                        title="Remover"
                        color="#dc3545"
                        onClick={() => handleDelete(wait)}
                      >
                        <MdClose color="#fff" size={12} />
                      </Button>
                    </div>
                  </div>
                </div>
              </Patient>
            ))}
          </Scroll>
        </WaitList>
      </nav>
    </Container>
  );
}

SideBar.propTypes = {
  active: PropTypes.bool,
  doubleClickEvent: PropTypes.func.isRequired,
  patients: PropTypes.arrayOf(PropTypes.object),
  professionals: PropTypes.arrayOf(PropTypes.object),
};

SideBar.defaultProps = {
  active: false,
  patients: undefined,
  professionals: undefined,
};
