import produce from 'immer';

const INITIAL_STATE = {
  agreement: null,
  agreements: [],
  loading: 0,
};

export default function agreement(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@agreement/CREATE_AGREEMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@agreement/CREATE_AGREEMENT_SUCCESS': {
        draft.loading = 0;
        break;
      }
      case '@agreement/CREATE_AGREEMENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@agreement/EDIT_AGREEMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@agreement/EDIT_AGREEMENT_SUCCESS': {
        draft.loading = 0;
        break;
      }
      case '@agreement/EDIT_AGREEMENT_FAILURE': {
        draft.loading = 0;
        break;
      }
      case '@agreement/LOAD_AGREEMENT_REQUEST': {
        draft.loading = 1;
        break;
      }
      case '@agreement/LOAD_AGREEMENT_SUCCESS': {
        draft.agreement = action.payload.agreement;
        draft.loading = 0;
        break;
      }
      case '@agreement/LOAD_AGREEMENT_FAILURE': {
        draft.loading = 0;
        break;
      }

      default:
    }
  });
}
