import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  justify-content: space-between;
`;

export const Navigation = styled.div`
  margin: 50px 50px 5px;
  display: flex;
  justify-content: center;

  > a {
    color: #fff;
    background-color: #f47920;
    padding: 5px;
    border-radius: 4px;
    &:hover {
      background-color: ${darken(0.05, '#f47920')};
    }
  }
`;

export const Reports = styled.ul`
  margin: 50px 350px 0;

  display: flex;
  flex-direction: column;
`;

export const Rep = styled.li`
  padding-bottom: 10px;
  border-bottom: 2px solid #999;
  & + li {
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > strong {
    font-size: 22px;
  }
  button {
    border-radius: 3px;
    background: #f47920;
    color: #fff;
    border: none;
    padding: 2px;
    &:hover {
      background: ${darken(0.08, '#f47920')};
    }
  }
`;

export const Menu = styled.div``;
export const Dates = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
`;
